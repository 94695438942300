<template>
  <div
    ref="el"
    class="quick-edit tw-flex tw-items-center tw-justify-end tw-p-4"
  >
    <template v-if="isEditing">
      <input
        v-model="inputValue"
        class="quick-edit__input"
        type="number"
        v-bind="$attrs"
        @focusout="clickOutside"
        @keypress.enter="ok"
        @keypress.escape.exact="close"
        @input="$emit('change-value')"
      />
      <div v-if="showButtons" class="quick-edit__buttons">
        <button
          class="quick-edit__button quick-edit__button--ok"
          data-cy="quick-edit-ok"
          @mousedown="ok"
        >
          <slot name="button-ok">{{ buttonOkText }}</slot>
        </button>
        <button
          class="quick-edit__button quick-edit__button--cancel"
          @mousedown="close"
        >
          <slot name="button-cancel">{{ buttonCancelText }}</slot>
        </button>
      </div>
    </template>
    <template v-else>
      <slot name="prepend"></slot>
      <span
        class="quick-edit__link quick-edit__link--is-clickable"
        :class="{
          ['quick-edit__link--is-empty']: isEmpty,
          ['quick-edit__link--is-required']: isRequired && isEmpty,
        }"
        @click="handleClick"
        @keypress.enter="handleClick"
      >
        <slot :value="displayValue" :raw-value="theValue">{{
          displayValue
        }}</slot>
      </span>
      <span class="tw-ml-1"><slot name="append"></slot></span>
    </template>
  </div>
</template>

<script>
const mune = keys =>
  keys.reduce((acc, cur) => {
    acc[cur] = cur
    return acc
  }, {})
const states = mune(['display', 'edit'])
const modes = mune(['ok', 'cancel', 'ignore'])
const events = mune([
  'input',
  'rawInput',
  'show',
  'close',
  'invalid',
  'focusin',
])

export default {
  name: 'QuickEdit',

  props: {
    value: {
      type: Number,
      default: 0,
    },

    buttonOkText: {
      type: String,
      default: 'Ok',
    },

    buttonCancelText: {
      type: String,
      default: 'Cancel',
    },

    emptyText: {
      type: String,
      default: 'Empty',
    },

    mode: {
      type: String,
      default: modes.ok,
    },

    showButtons: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      inputState: states.display,
      theValue: '',
      inputValue: '',
    }
  },

  computed: {
    isEditing() {
      return states.edit === this.inputState
    },

    isEmpty() {
      return '' === this.theValue || null === this.theValue
    },

    isRequired() {
      return this.$attrs.required || '' === this.$attrs.required
    },

    displayValue() {
      return this.isEmpty ? this.emptyText : this.theValue
    },
  },

  watch: {
    value(value) {
      this.setValue(value)
    },
  },

  created() {
    this.setValue(this.value)
  },

  methods: {
    setValue(value) {
      this.theValue = value

      this.inputValue = value
    },

    handleClick() {
      this.show()
    },

    clickOutside() {
      if (this.inputState !== states.edit) return

      if (modes.ok === this.mode) this.ok(false)
      else if (modes.cancel === this.mode) this.close(false)
    },

    ok(doFocus = true) {
      this.theValue = this.inputValue

      this.$emit(events.input, this.theValue)

      this.$emit(events.rawInput, this.inputValue)

      this.close(doFocus)
    },

    show(doFocus = true) {
      this.inputValue = this.theValue

      this.inputState = states.edit

      this.$emit(events.show, this.theValue)

      doFocus && this.focus()
    },

    close(doFocus = true) {
      this.inputState = states.display

      this.$emit(events.close, this.theValue)

      doFocus && this.focus()
    },

    focus() {
      setTimeout(() => {
        const className = this.isEditing ? 'input' : 'span'

        const el = this.$refs.el && this.$refs.el.querySelector(className)

        el && el.focus()
      }, 0)
    },
  },
}
</script>

<style lang="scss">
$link-color: #101010;
$link-hover-color: #101010;
$link-background-color: #f2f2f2;
$primary-color: #02a4ec;
$primary-text-color: #fff;
$secondary-text-color: #8f9cb2;
$primary-border-color: #357ebd;
$danger-color: #dc3545;
$default-color: #fff;
$default-text-color: #101010;
$border-color: #02a4ec;
$quick-edit-width: 80px;
$quick-edit-height: 32px;
$quick-edit-input-height: 24px;

.quick-edit {
  height: $quick-edit-height;

  &__link {
    width: $quick-edit-width;
    text-align: right;
    background-color: $link-background-color;
    padding: 3px 8px;
    white-space: pre-wrap;
    color: $link-color;

    &--is-clickable {
      cursor: pointer;
      &:hover {
        color: $link-hover-color;
      }
    }

    &--is-empty {
      color: gray;
    }

    &--is-required {
      color: $danger-color;
    }
  }

  &__input {
    background-color: transparent;
    color: $default-text-color;
    border: 1px solid $border-color;
    width: $quick-edit-width;
    height: $quick-edit-input-height;
    padding: 3px 8px;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &__buttons {
    margin-top: 0;
    margin-left: 4px;
  }

  &__button {
    height: $quick-edit-input-height;
    min-width: $quick-edit-input-height;
    border: none;
    border-radius: 2px;

    &--ok {
      color: $default-color;
      background-color: $primary-color;
      border-color: $primary-border-color;
    }

    &--cancel {
      color: $secondary-text-color;
      background-color: $link-background-color;
      margin-left: 4px;
    }
  }
}

[multiple].quick-edit__input,
textarea.quick-edit__input {
  height: unset;
  min-height: $quick-edit-height * 2;
  display: block;
}

.quick-edit__input:not(textarea):not([multiple]) + .quick-edit__buttons,
label + .quick-edit__buttons {
  display: inline;
  margin-left: 8px;
}
</style>
