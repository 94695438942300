<template>
  <div class="tw-w-full">
    <div class="lg:tw-flex tw-pt-4 tw--mx-2">
      <div class="tw-w-full lg:tw-w-2/3 tw-px-2">
        <div v-if="hasAllowance" class="card" data-cy="leave-type-summary">
          <AllowanceSummaryChart :employment-allowance="allowanceSummary" />
        </div>

        <PendingApprovalsSummaryTable
          :loading="pendingApprovalsLoading"
          :pending-approvals="pendingApprovals"
          :cancelling="cancelling"
          :approving="approving"
          @show-info="addPendingApprovalToQuery($event)"
          @decline="declineApprovals"
          @approve="approveApprovals"
        />

        <MyLeaveBreakdownsTable
          :data-leave="myLeave"
          :leave-loading="myLeaveLoading"
          @show-info="addLeaveRequestToQuery"
          @include-declined-changed="myLeaveParams.includeDeclined = $event"
          @include-taken-changed="myLeaveParams.includeTaken = $event"
          @include-booked-changed="myLeaveParams.includeBooked = $event"
        />
      </div>
      <div class="tw-w-full sm:tw-w-2/3 md:tw-w-1/2 lg:tw-w-1/3 tw-px-2">
        <NextSevenDays
          :selected-scope="selectedFilters.scope"
          :display-filters="displayFilters"
          :next-seven-days-leaves="nextSevenDaysLeaves"
          :birthdays="nextSevenDaysBirthdays"
          :holidays="nextSevenDaysHolidays"
          :work-anniversaries="nextSevenDaysWorkAnniversaries"
          @change-filters="changeFilters"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import EventBus from '@/plugins/event-bus'
import ValidatesForm from '@/mixins/ValidatesForm'
import { AllowanceReport, PendingApprovals } from '@/api'
import NextSevenDaysSummaryQuery from '@/graphql/queries/next-seven-days-summary'

const AllowanceSummaryChart = () => import('@/components/AllowanceSummaryChart')
const MyLeaveBreakdownsTable = () =>
  import('@/components/my-leave-date/MyLeaveBreakdownsTable')
const NextSevenDays = () => import('@/components/next-seven-days/NextSevenDays')
const PendingApprovalsSummaryTable = () =>
  import('@/components/pending-approvals/PendingApprovalsSummaryTable.vue')

export default {
  name: 'LeaveSummary',

  middleware: 'auth',

  components: {
    MyLeaveBreakdownsTable,
    AllowanceSummaryChart,
    NextSevenDays,
    PendingApprovalsSummaryTable,
  },

  mixins: [ValidatesForm],

  data() {
    return {
      allowanceSummary: null,
      pendingApprovals: [],
      pendingApprovalsLoading: false,
      nextSevenDaysLeaves: [],
      nextSevenDaysHolidays: [],
      nextSevenDaysBirthdays: [],
      nextSevenDaysWorkAnniversaries: [],
      myLeave: [],
      myLeaveLoading: false,
      myLeaveParams: {
        includeBooked: true,
        includeTaken: false,
        includeDeclined: false,
      },
      selectedFilters: {
        scope: 'company',
      },
      cancelling: false,
      approving: false,
    }
  },

  computed: {
    displayFilters() {
      return Object.keys(this.$options.filters)
    },

    canViewPendingApprovals() {
      return this.activeEmployment.is_admin || this.activeEmployment.is_approver
    },

    nextSevenDaysRange() {
      const from = moment()
        .startOf('day')
        .utc(true)
        .toISOString()

      const to = moment()
        .add(7, 'day')
        .endOf('day')
        .utc(true)
        .toISOString()

      return `${from},${to}`
    },

    isDefaultLeaveTypeHasAllowanceType() {
      return this.activeCompany.default_leave_type.allowance_type_id
    },

    hasAllowance() {
      return this.allowanceSummary && this.allowanceSummary.totalAllowance() > 0
    },
  },

  watch: {
    '$route.query'(query) {
      if (!query.hasOwnProperty('leave-request')) {
        this.fetchLeaves()
      }
    },

    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.fetchLeaves()
      },
    },

    myLeaveParams: {
      deep: true,
      handler: 'fetchMyLeave',
    },
  },

  created() {
    this.$options.filters = {
      Leave: 'fetchNextSevenDaysApprovedLeaves',
      'Public Holidays': 'fetchNextSevenDaysHolidays',
      Birthdays: 'fetchNextSevenDaysBirthdays',
      'Work Anniversaries': 'fetchNextSevenDaysWorkAnniversaries',
    }

    EventBus.$on('notification-fetched', () => this.fetchLeaves())
  },

  methods: {
    addLeaveRequestToQuery(leave) {
      window.document.body.click()

      this.$router.push(
        {
          query: {
            ...this.$route.query,
            'leave-request': leave.id,
          },
        },
        () => {}
      )
    },

    addPendingApprovalToQuery(request) {
      let path = request.isLeave() ? 'leave-request' : 'overtime'

      window.document.body.click()

      this.$router.push(
        {
          query: {
            ...this.$route.query,
            [path]: request.getKey(),
          },
        },
        () => {}
      )
    },

    fetchLeaves() {
      this.fetchPendingApprovals()
      this.fetchAllowanceSummary()
      this.fetchMyLeave()
      this.fetchNextSevenDays()
    },

    async fetchPendingApprovals() {
      if (this.canViewPendingApprovals === false) return

      this.pendingApprovalsLoading = true

      this.pendingApprovals = await PendingApprovals.get(this.activeCompany)

      this.pendingApprovalsLoading = false
    },

    async fetchAllowanceSummary() {
      if (!this.isDefaultLeaveTypeHasAllowanceType) return

      const paginatedAllowances = await AllowanceReport.getDetailsAllowances({
        company_id: this.activeCompany.id,
        date: moment().format('YYYY-MM-DD'),
        employee: this.activeEmployment.id,
        allowance_type: this.activeCompany.default_leave_type.allowance_type_id,
      })

      this.allowanceSummary = paginatedAllowances.items().first()
    },

    async fetchMyLeave() {
      try {
        this.myLeaveLoading = true

        let tableData = []

        if (this.myLeaveParams.includeBooked) {
          tableData = await this.fetchLeavesByType('booked-leaves')
        }

        if (this.myLeaveParams.includeTaken) {
          tableData = [
            ...tableData,
            ...(await this.fetchLeavesByType('taken-leaves')),
          ]
        }

        if (this.myLeaveParams.includeDeclined) {
          tableData = [
            ...tableData,
            ...(await this.fetchLeavesByType('rejected-leaves')),
          ]
        }

        this.myLeave = tableData

        this.myLeaveLoading = false
      } catch (e) {
        this.myLeaveLoading = false
      }
    },

    async fetchLeavesByType(type) {
      try {
        const { data } = await this.$http.get(type, {
          params: {
            company: this.activeCompany.id,
            employment: this.activeEmployment.id,
          },
        })

        return data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    fetchNextSevenDays() {
      this.fetchNextSevenDaysApprovedLeaves()
      this.fetchNextSevenDaysBirthdays()
      this.fetchNextSevenDaysWorkAnniversaries()
    },

    async fetchNextSevenDaysApprovedLeaves() {
      this.nextSevenDaysLeaves = await this.fetchNextSevenDaysDataByScope(
        'leaves',
        { status: 'approved' }
      )
    },

    async fetchNextSevenDaysBirthdays() {
      this.nextSevenDaysBirthdays = await this.fetchNextSevenDaysDataByScope(
        'birthdays'
      )
    },

    async fetchNextSevenDaysHolidays() {
      try {
        await this.$apollo.queries.companySchedule.refetch()
      } catch (error) {
        this.validateGraphQLFromResponse(error, false)
      }
    },

    async fetchNextSevenDaysWorkAnniversaries() {
      this.nextSevenDaysWorkAnniversaries = await this.fetchNextSevenDaysDataByScope(
        'work-anniversaries'
      )
    },

    async fetchNextSevenDaysDataByScope(url, params = null) {
      return await this.fetchNextSevenDaysData(url, {
        department:
          this.selectedFilters.scope === 'department'
            ? this.activeEmployment.department_id
            : null,
        ...params,
      })
    },

    async fetchNextSevenDaysData(url, params = null) {
      return this.fetchCompanyDataWithinPeriod(url, params)
    },

    async fetchCompanyDataWithinPeriod(url, params = null) {
      try {
        const { data } = await this.$http.get(url, {
          params: {
            company: this.activeCompany.id,
            within: this.nextSevenDaysRange,
            ...params,
          },
        })

        return data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    changeFilters(filters) {
      this.selectedFilters.scope = filters.scope
      this.selectedFilters.displayFilters = filters.displayFilters
      this.filterNextSevenDays()
    },

    filterNextSevenDays() {
      this.emptyNextSevenDaysData()
      for (let filter of this.selectedFilters.displayFilters) {
        this[this.$options.filters[filter]]()
      }
    },

    emptyNextSevenDaysData() {
      this.nextSevenDaysLeaves.splice(0)
      this.nextSevenDaysBirthdays.splice(0)
      this.nextSevenDaysHolidays.splice(0)
      this.nextSevenDaysWorkAnniversaries.splice(0)
    },

    async declineApprovals() {
      const confirmed = await this.confirm(
        'Are you sure you want to decline all requests?',
        'Confirm Decline'
      )

      if (!confirmed) return

      this.cancelling = true

      await PendingApprovals.decline(this.activeCompany)

      await this.fetchPendingApprovals()

      this.cancelling = false
    },

    async approveApprovals() {
      const confirmed = await this.confirm(
        'Are you sure you want to approve all requests?',
        'Confirm Approval'
      )

      if (!confirmed) return

      this.approving = true

      await PendingApprovals.approve(this.activeCompany)

      await this.fetchPendingApprovals()
      this.fetchNextSevenDaysApprovedLeaves()

      this.approving = false
    },

    getNextSevenDayParameters() {
      return {
        company: this.activeCompany.id,
        date: moment()
          .startOf('day')
          .utc(true)
          .format('YYYY-MM-DD'),
        interval: 'Days7',
        employmentsQuery: {
          offset: 0,
          limit: 10000,
          employmentIds: [],
          departmentIds: [],
          orderBy: null,
        },
      }
    },
  },

  apollo: {
    companySchedule: {
      debounce: 50,
      query: NextSevenDaysSummaryQuery,
      fetchPolicy: 'no-cache',
      variables() {
        return this.getNextSevenDayParameters()
      },
      result(response) {
        this.nextSevenDaysHolidays = response.data.companySchedule.holidays
      },
      error() {},
    },
  },
}
</script>
