<template>
  <div class="card" data-cy="my-leave-summary">
    <div class="tw-mt-2 tw-mb-4 tw-flex tw-items-center tw-justify-between">
      <h2 class="tw-text-gray-800">My Leave</h2>

      <div>
        <button
          ref="reference"
          class="tw-px-6 tw-py-2 tw-flex tw-items-center tw-rounded-lg tw-shadow-sm tw-cursor-pointer tw-no-underline tw-text-gray-700"
          type="button"
          data-cy="filter-leaves"
        >
          <SvgIcon
            name="filter"
            class="tw-w-4 tw-h-4 tw-mr-2 tw-text-gray-700"
          />
          <span class="tw-font-semibold tw-truncate">Filter</span>
        </button>
        <div v-show="false" ref="tooltip">
          <div
            class="tw-p-4 tw-rounded tw-overflow-auto"
            style="min-width: 163px;"
          >
            <div class="tw-mb-4 tw-flex tw-items-center">
              <input
                id="booked"
                checked
                class="magic-checkbox"
                name="booked"
                type="checkbox"
                data-cy="leave-booked-checkbox"
                @change="$emit('include-booked-changed', $event.target.checked)"
              />
              <label
                for="booked"
                class="tw-select-none magic-checkbox-label tw-w-full tw-font-normal"
                >Booked</label
              >
            </div>
            <div class="tw-mb-4 tw-flex tw-items-center">
              <input
                id="taken"
                class="magic-checkbox"
                name="taken"
                type="checkbox"
                data-cy="leave-taken-checkbox"
                @change="$emit('include-taken-changed', $event.target.checked)"
              />
              <label
                for="taken"
                class="tw-select-none magic-checkbox-label tw-w-full tw-font-normal"
                >Taken</label
              >
            </div>
            <div class="tw-flex tw-items-center">
              <input
                id="declined"
                class="magic-checkbox"
                name="declined"
                type="checkbox"
                @change="
                  $emit('include-declined-changed', $event.target.checked)
                "
              />
              <label
                for="declined"
                class="tw-select-none magic-checkbox-label tw-w-full tw-font-normal"
                >Declined / Cancelled</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-scroll-hint class="table-responsive scroll-hint tw-relative">
      <table
        v-if="dataLeave.length"
        class="ld-table ld-table-clickable tw-mb-0"
        data-cy="leave-summary-breakdown-table"
      >
        <thead>
          <tr>
            <th>Leave Type</th>
            <th>From</th>
            <th>Amount</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <MyLeaveDateSingleTableRow
            v-for="singleLeave in sortedLeave"
            :key="singleLeave.id"
            :leave="singleLeave"
            @show-info="showLeaveInfo"
          />
        </tbody>
      </table>
      <Loading loader="dots" :is-full-page="false" :active="leaveLoading" />
      <div
        v-if="!leaveLoading && dataLeave.length === 0"
        class="tw-border-t tw-border-gray-350 tw-py-4"
      >
        <div class="tw-flex tw-flex-col tw-items-center">
          <div class="tw-mt-8">
            <img
              class="tw-w-auto tw-h-24"
              src="@/assets/img/no-leaves-booked.png"
            />
          </div>
          <div class="tw-mb-1 tw-text-gray-800 tw-text-lg">
            There's no leave to show :(
          </div>
          <div class="tw-mb-4 tw-text-gray-600">
            Fancy some time off?
          </div>
          <SpinnerButton
            data-cy="book-leave-btn"
            type="button"
            @click="showRequestForm"
          >
            <span class="tw-uppercase tw-text-thin tw-font-normal">Book</span>
          </SpinnerButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import { sortBy } from 'lodash-es'
import Loading from 'vue-loading-overlay'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import RequestForm from '@/components/requests/RequestForm'
import SpinnerButton from '@/components/SpinnerButton'

const MyLeaveDateSingleTableRow = () =>
  import('@/components/my-leave-date/MyLeaveDateSingleTableRow')

export default {
  name: 'MyLeaveBreakdownsTable',

  components: {
    SpinnerButton,
    MyLeaveDateSingleTableRow,
    Loading,
  },

  mixins: [FormatDate, ValidatesForm],

  props: {
    dataLeave: {
      type: Array,
      required: true,
    },
    leaveLoading: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      editing: false,
      loading: false,
      employee: [],
      leave: [],
    }
  },

  computed: {
    sortedLeave() {
      return sortBy(this.dataLeave, 'from')
    },
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.reference, {
      html: this.$refs.tooltip.firstChild,
      trigger: 'click',
      distance: 8,
      placement: 'bottom-end',
      interactive: true,
      theme: 'light',
      animation: 'scale',
      inertia: true,
      arrow: true,
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },

  methods: {
    showLeaveInfo(e) {
      if (this.tippy.state.visible) {
        return this.tippy.hide()
      }

      this.$emit('show-info', e)
    },

    reset() {
      this.leave = null
      this.editing = false
    },

    showRequestForm() {
      const form = new RequestForm({
        success: panel => this.$showPanel(panel),
        failed: async message => {
          const confirmed = await this.confirm(message)

          if (!confirmed) return

          return this.$router.push({ name: 'billing' }, () => {})
        },
      })

      form.open(this.activeCompany, this.activeEmployment)
    },
  },
}
</script>
<style scoped>
.vld-overlay {
  z-index: 99 !important;
}
</style>
