<template>
  <tr>
    <td>{{ jobTitle }}</td>
    <td>{{ employment.full_name }}</td>
    <td>{{ email }}</td>
    <td>{{ departmentName }}</td>
    <td>{{ employeeCode }}</td>
    <td>{{ dateOfBirth }}</td>
    <td>{{ startDate }}</td>
    <td>{{ yearsOfService }}</td>
    <td>{{ employment.allowance_unit }}</td>
    <td>{{ employment.hours_per_working_day }}</td>
    <td>{{ employment.timezone }}</td>
    <td>{{ holidayLocation }}</td>
    <td>{{ employment.is_admin }}</td>
    <td>{{ employment.is_approver }}</td>
  </tr>
</template>

<script>
import moment from 'moment-timezone'
import FormatDate from '../../../mixins/FormatDate'

export default {
  name: 'EmploymentReportsTableRow',

  mixins: [FormatDate],

  props: {
    employment: {
      type: Object,
      required: true,
    },
  },

  computed: {
    jobTitle() {
      return this.employment.job_title ? this.employment.job_title : '-'
    },

    email() {
      return this.employment.email ? this.employment.email : '-'
    },

    departmentName() {
      return this.employment.department_name
        ? this.employment.department_name
        : '-'
    },

    employeeCode() {
      return this.employment.employee_code ? this.employment.employee_code : '-'
    },

    startDate() {
      if (!this.employment.start_date) return '-'

      return this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
        moment.utc(this.employment.start_date),
        'utc'
      )
    },

    dateOfBirth() {
      if (!this.employment.date_of_birth) return '-'

      return this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
        moment.utc(this.employment.date_of_birth),
        'utc'
      )
    },

    yearsOfService() {
      return this.employment.years_of_service
        ? this.employment.years_of_service
        : '-'
    },

    holidayLocation() {
      return this.employment.holiday_location
        ? this.employment.holiday_location
        : '-'
    },
  },
}
</script>
