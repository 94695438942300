<template>
  <div
    class="tw-w-full sm:tw-w-1/2 md:tw-w-1/3 lg:tw-w-full tw-mb-4 sm:tw-px-2"
    data-cy="leave-summary-component"
  >
    <div class="card tw-mb-0">
      <div class="tw-mb-5 tw-text-xl tw-font-semibold">
        Summary
      </div>
      <div class="tw-leading-loose tw-text-gray-700">
        <ol class="">
          <li
            v-for="(leaveType, index) in sortedLeaveTypeWithLeaveCount"
            :key="index"
            class="tw-flex tw-justify-between tw-text-gray-800 tw-text-lg"
          >
            <div class="tw-flex tw-items-center tw-justify-start">
              <span
                :style="{
                  width: '10px',
                  height: '10px',
                  background: leaveType.colour,
                }"
                class="tw-rounded-full tw-mr-4"
                data-cy="leave-colour"
              ></span>
              <span data-cy="leave-name">{{ leaveType.name }}</span>
            </div>
            <div data-cy="leave-count">
              <span>
                {{ leaveType.count }}
              </span>
              &nbsp;
              <span>
                {{ leaveType.allowanceUnit | pluralize(leaveType.count) }}
              </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy, reduce } from 'lodash-es'
import FormatNumbers from '@/mixins/FormatNumbers'

export default {
  name: 'LeaveSummary',

  mixins: [FormatNumbers],

  props: {
    dataLeave: {
      type: Array,
      required: true,
    },
  },

  computed: {
    leaveTypeDataWithLeaveCount() {
      const groups = reduce(
        this.dataLeave,
        (leaves, leave) => {
          let group = leaves[leave.type_id] ?? {
            name: leave.type.name,
            colour: leave.type.colour,
            count: 0,
            allowanceUnit: leave.allowance_unit_is_days ? 'days' : 'hours',
          }

          group.count += leave.deducted_allowance.amount

          leaves[leave.type_id] = {
            ...group,
            count: this.toFixed(group.count, 2),
          }

          return leaves
        },
        {}
      )

      return Object.values(groups)
    },

    sortedLeaveTypeWithLeaveCount() {
      return orderBy(
        this.leaveTypeDataWithLeaveCount,
        ['count', 'name'],
        ['desc', 'asc']
      )
    },
  },
}
</script>
