<template>
  <div>
    <div
      class="tw-flex tw-justify-center tw-items-center tw-min-h-screen tw-bg-gray-200 tw-p-4 ie_tw-h-screen"
    >
      <div class="tw-w-full w-360">
        <form
          class="tw-bg-white tw-shadow-md tw-rounded tw-px-8 tw-pt-6 tw-pb-8 tw-mb-4"
          @submit.prevent="submit"
        >
          <div>
            <a :href="marketingSiteUrl" title="Click to visit">
              <HeaderLogo />
            </a>
          </div>
          <p class="tw-font-semibold tw-my-8 tw-text-2xl tw-text-gray-800">
            Sign in to your company
          </p>
          <div class="tw-mb-8">
            <label class="form-label" for="domain">
              Company Domain
            </label>
            <div class="tw-flex tw-items-center">
              <input
                id="domain"
                v-validate="'required|max:191'"
                v-focus
                :value="domain"
                name="domain"
                data-vv-as="domain"
                class="form-control tw-mr-3"
                type="text"
                placeholder="leavedates"
                autocomplete="off"
                @input="domain = $event.target.value.toLowerCase()"
              />
              <div class="tw-select-none">.leavedates.com</div>
            </div>
            <p
              v-show="errors.has('domain_name')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
              style="line-height: 12px;"
            >
              {{ errors.first('domain_name') }}
            </p>
          </div>
          <div v-if="hasAuthProviders">
            <label class="form-label">
              Auth Provider
            </label>
            <div class="tw-mb-8">
              <div
                v-for="authProvider in authProviders"
                :key="authProvider"
                class="tw-mb-4 tw-flex tw-items-center tw-justify-start"
              >
                <input
                  :id="authProvider"
                  v-model="selectedProvider"
                  v-validate="'required'"
                  class="magic-radio"
                  name="providers"
                  type="radio"
                  :value="authProvider"
                />
                <label
                  :for="authProvider"
                  class="tw-select-none magic-radio-label tw-w-full tw-font-normal"
                  >{{ getProviderName(authProvider) }}</label
                >
              </div>
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between">
            <SpinnerButton
              :disabled="!valid || loading"
              :loading="loading"
              :spinner-only="true"
              cypress-attribute="btn-submit-company-domain"
              type="submit"
            >
              Continue
            </SpinnerButton>

            <router-link
              :to="{ name: 'signin' }"
              class="tw-mx-0 btn btn-borderless btn-link btn-link_focus tw-text-blue-500"
            >
              Back to sign in
            </router-link>
          </div>
        </form>

        <auth-footer />
      </div>
    </div>
  </div>
</template>

<script>
import HandleSingleSignOn from '../../mixins/HandleSingleSignOn'
import AuthFooter from './../../components/AuthFooter'
import SpinnerButton from '@/components/SpinnerButton'
import ValidatesForm from './../../mixins/ValidatesForm'
import MarketingSiteUrl from '../../mixins/MarketingSiteUrl'
import { debounce } from 'lodash-es'
import HeaderLogo from '@/components/HeaderLogo'

export default {
  name: 'CompanyDomain',

  middleware: 'guest',

  layout: 'DefaultLayout',

  components: { HeaderLogo, AuthFooter, SpinnerButton },

  mixins: [ValidatesForm, MarketingSiteUrl, HandleSingleSignOn],

  data: () => ({
    domain: '',
    loading: false,
    selectedProvider: '',
    hasAuthProviders: false,
    authProviders: [],
  }),

  watch: {
    domain: {
      handler(domain) {
        this.showAuthProviderNames(domain)
      },
    },
  },

  methods: {
    getProviderName(provider) {
      return provider.charAt(0).toUpperCase() + provider.slice(1)
    },

    async submit() {
      try {
        this.loading = true

        await this.validate()

        if (!this.valid) return

        const data = await this.getAuthProviders(this.domain)

        if (!data.length) {
          this.error(
            'No authentication providers have been added to this company.'
          )
          this.loading = false
        } else if (data.length === 1) {
          await this.redirectToProvider(this.domain, data[0])
        } else if (this.selectedProvider) {
          await this.redirectToProvider(this.domain, this.selectedProvider)
        } else {
          this.loading = false
        }
      } catch (e) {
        this.error('The company domain does not exist.')
        this.loading = false
      }
    },

    showAuthProviderNames: debounce(async function(domain) {
      this.hasAuthProviders = false
      this.authProviders = []

      const data = await this.getAuthProviders(domain)

      this.authProviders = data
      this.hasAuthProviders = data && data.length > 1
    }, 300),

    async getAuthProviders(domain) {
      try {
        const { data } = await this.$http.get('auth-providers/names', {
          params: {
            domain_name: domain,
          },
        })

        this.errors.clear()

        return data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
        this.loading = false
      }
    },
  },
}
</script>
