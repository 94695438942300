import http from '@/plugins/http'

export default class ToilVisibilitySettings {
  static async update(visibilitySettings) {
    return await http.put(
      `time-off-in-lieu-visibility-settings/${visibilitySettings.id}`,
      {
        ...visibilitySettings,
      }
    )
  }
}
