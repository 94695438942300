<template>
  <Modal
    id="review-reminder"
    :classes="[
      'tw-shadow-md',
      'tw-bg-white',
      'tw-rounded-lg',
      'modal-overflow-visible',
    ]"
    :max-width="500"
    name="review-reminder"
    width="95%"
    height="auto"
    adaptive
    scrollable
    :click-to-close="false"
  >
    <div class="modal-header">
      <div class="tw-flex tw-justify-between">
        <div>
          <p class="modal-title review-form-title">
            {{ reviewProviderTitle }}
          </p>
        </div>
        <div>
          <button
            class="modal-close"
            @click.prevent="$modal.hide('review-reminder')"
          >
            <svg-icon name="close" class="tw-w-4 tw-h-4" />
          </button>
        </div>
      </div>
    </div>

    <div class="tw-mt-3 tw-p-3" data-cy="review-popup">
      <div class="review-form-body tw-w-full tw-px-3">
        <div
          class="review-form-heading tw-font-semibold"
          data-cy="review-greeting"
        >
          Thank you for using<br />
          <span class="tw-text-blue-500">Leave Dates</span>
        </div>
        <p class="review-form-greeting">We hope you love it!</p>
        <br />
        <p>If you do, would you consider posting an online review?</p>
        <p>
          This helps us to continue providing a great service and gives
          potential buyers confidence in choosing us. It takes less than 5
          minutes.
        </p>
      </div>

      <div class="tw-w-full">
        <div class="tw-px-3 tw-pb-3 tw-pt-6">
          <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
            <button
              class="btn btn-link tw-text-red-500 tw-px-0"
              data-cy="btn-dont-ask"
              @click.stop="disableReviewReminders"
            >
              Don't ask again
            </button>

            <div class="tw-flex tw-items-center tw-justify-between">
              <button
                class="btn tw-border tw-border-gray-400 hover:tw-bg-gray-300 tw-text-gray-700 tw-mr-4"
                data-cy="btn-review-reminder"
                @click.stop="$modal.hide('review-reminder')"
              >
                Maybe Later
              </button>
              <button
                data-cy="btn-write-review"
                class="btn btn-blue tw-border tw-border-blue-500 hover:tw-border-blue-600"
                @click.stop="addReview"
              >
                Write a Review
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment-timezone'
import { orderBy, last, random } from 'lodash-es'
import EventBus from '@/plugins/event-bus'
import ValidatesForm from '@/mixins/ValidatesForm'

export default {
  name: 'ReviewReminder',

  mixins: [ValidatesForm],

  data() {
    return {
      reviewProviders: [],
      userReviews: [],
    }
  },

  computed: {
    shouldDisplayReviewReminder() {
      if (!this.authUser.is_review_reminders_enabled) {
        return false
      }

      if (!this.nextReviewProvider) {
        return false
      }

      if (!this.isUserOlderThanThreeMonths) {
        return false
      }

      if (!this.isLastReminderOlderThanThreeMonths) {
        return false
      }

      if (!this.isLastReviewOlderThanSixMonths) {
        return false
      }

      if (window.Cypress) {
        return random(1, 1) === 1
      }
      return random(1, 10) === 10
    },

    isLastReminderOlderThanThreeMonths() {
      if (!this.authUser.review_reminded_at) {
        return true
      }

      return (
        moment
          .utc()
          .diff(moment.utc(this.authUser.review_reminded_at), 'months', true) >=
        3
      )
    },

    isLastReviewOlderThanSixMonths() {
      const lastReview = last(orderBy(this.userReviews, 'created_at'))

      if (!lastReview) return true

      return moment.utc().diff(lastReview.created_at, 'months', true) >= 6
    },

    isUserOlderThanThreeMonths() {
      return (
        moment
          .utc()
          .diff(moment.utc(this.authUser.created_at), 'months', true) >= 3
      )
    },

    nextReviewProvider() {
      return orderBy(this.reviewProviders, 'priority').find(this.hasNotReviewed)
    },

    reviewProviderTitle() {
      return this.nextReviewProvider ? this.nextReviewProvider.title : ''
    },
  },

  created() {
    this.fetchReviewProviders()
    this.fetchUserReviews()

    EventBus.$on('leave-requested', () => this.showReviewReminder())
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    hasNotReviewed(provider) {
      return !this.userReviews.some(
        review => review.provider_id === provider.id
      )
    },

    async addReview() {
      this.loading = true

      try {
        const { data } = await this.$http.post('me/reviews', {
          review_provider_id: this.nextReviewProvider.id,
        })

        window.open(data.review_link, '_blank')

        this.fetchUserReviews()
      } catch ({ response }) {
        this.validateFromResponse(response, true)
      }

      this.$modal.hide('review-reminder')

      this.loading = false
    },

    async disableReviewReminders() {
      this.loading = true

      try {
        await this.$http.delete('me/review-reminders')
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.$modal.hide('review-reminder')

      this.loading = false
    },

    async fetchReviewProviders() {
      try {
        const { data } = await this.$http.get('review-providers')

        this.reviewProviders = data

        return data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchUserReviews() {
      try {
        const { data } = await this.$http.get('me/reviews')

        this.userReviews = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async updateReviewRemindedDate() {
      try {
        await this.$http.put('me/review-reminders')
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async showReviewReminder() {
      if (!this.shouldDisplayReviewReminder) {
        return
      }

      this.$modal.show('review-reminder')

      await this.updateReviewRemindedDate()
      return this.fetchUser()
    },
  },
}
</script>

<style scoped lang="scss">
.review-form-title {
  font-size: 1.15rem;
}

.review-form-heading {
  font-size: 2.1rem;
}

.review-form-greeting::after {
  content: ' \2764';
}

.review-form-body p {
  line-height: 2.5;
}
</style>
