<template>
  <div
    class="allowance-input tw-mt-4 tw-pt-4 tw-pb-3 tw-border tw-border-blue-500 tw-rounded-lg"
  >
    <form class="tw-w-full" @submit.prevent="submit">
      <div class="tw-px-5 tw-pb-1">
        <div class="form-group">
          <div class="tw-w-full">
            <label class="form-label" for="name">
              Allowance Name <span class="required-field">&#42;</span>
            </label>
            <input
              id="name"
              v-model="allowance.name"
              v-validate="'required|max:50'"
              v-focus
              data-vv-name="allowance-name"
              data-vv-as="allowance name"
              data-cy="allowance-name"
              class="form-control"
              type="text"
              autocomplete="off"
            />
            <p
              v-show="errors.has('name')"
              data-cy="allowance-error"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('name') }}
            </p>
          </div>
        </div>

        <div class="form-group tw-mb-3">
          <div class="tw-w-full">
            <label class="form-label" for="description">
              Allowance Description
            </label>
            <input
              id="description"
              v-model="allowance.description"
              v-validate="'max:100'"
              data-vv-name="description"
              data-vv-as="allowance description"
              data-cy="allowance-desc"
              class="form-control"
              autocomplete="off"
              type="text"
            />
            <p
              v-show="errors.has('description')"
              data-cy="allowance-desc-error"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('description') }}
            </p>
          </div>
        </div>
      </div>
      <div class="tw-pt-3 tw-border-t tw-border-gray-300"></div>

      <div class="tw-px-5">
        <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
          <div class="tw-flex tw-items-center tw-justify-between">
            <button
              type="button"
              class="btn btn-link tw-text-blue tw-px-0 tw-font-medium tw-mr-6"
              @click.stop="cancel"
            >
              Cancel
            </button>

            <SpinnerButton
              :disabled="!valid || loading"
              :loading="loading"
              :spinner-only="true"
              class="submit-button"
              type="submit"
            >
              Update
            </SpinnerButton>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SpinnerButton from '@/components/SpinnerButton'
import ValidatesForm from './../../mixins/ValidatesForm'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AllowanceSummaryInput',

  components: { SpinnerButton },

  mixins: [ValidatesForm],

  props: {
    allowance: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState('allowances', {
      current: 'current',
    }),
  },

  methods: {
    ...mapActions('allowances', {
      getAllAllowances: 'all',
      updateAllowance: 'update',
    }),

    async submit() {
      this.errors.clear()
      const valid = await this.validate()

      if (!valid) return

      this.loading = true

      try {
        const payload = {
          ...this.allowance,
          company_id: this.activeCompany.id,
        }

        await this.updateAllowance({ payload, allowance: this.current })

        this.getAllAllowances()

        this.success('Allowance updated successfully.')
        this.$emit('cancel')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false
    },

    cancel() {
      this.errors.clear()
      this.$emit('cancel')
    },
  },
}
</script>

<style scoped></style>
