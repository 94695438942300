<template>
  <table class="ld-table ld-table-clickable" data-cy="departments-table">
    <thead>
      <tr>
        <th>Department Name</th>
        <th style="width: 100px" />
      </tr>
    </thead>
    <tbody>
      <template v-if="departments.length">
        <department-table-row
          v-for="department in departments"
          :key="department.id"
          :department="department"
          @edit-department="$emit('edit-department', department)"
          @delete-department="$emit('delete-department', department)"
        />
      </template>
      <tr v-else>
        <td class="tw-bg-white" colspan="2"><no-data /></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const NoData = () => import('./../NoData')
const DepartmentTableRow = () => import('./DepartmentTableRow')

export default {
  name: 'DepartmentTable',

  components: { DepartmentTableRow, NoData },

  props: {
    departments: {
      type: Array,
      required: true,
    },
  },
}
</script>
