var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"tw-w-full",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('show-edit-breakdown-form', _vm.breakdown)}}},[_c('div',{staticClass:"tw-flex tw-justify-between form-control"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-start"},[_c('div',{staticClass:"tw-truncate tw-mr-3",staticStyle:{"max-width":"10.5rem"},attrs:{"title":_vm.breakdown.name}},[_vm._v(" "+_vm._s(_vm.breakdown.name)+" ")]),_c('span',{staticClass:"tw-px-3 tw-py-2 tw-mr-3 tw-rounded-full tw-uppercase tw-text-xs tw-font-semibold",class:_vm.isCarryOverPolicy
            ? 'tw-text-orange-500 tw-bg-orange-200'
            : 'tw-text-blue-500 tw-bg-blue-200'},[_vm._v(" "+_vm._s(_vm.policyLabel)+" ")])]),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end"},[_c('div',{staticClass:"sm:tw-flex tw-border-r tw-border-gray-300 tw-pr-3"},[_c('div',{staticClass:"tw-px-3 tw-py-2"},[_vm._v(" "+_vm._s(_vm.defaultAmountInDaysByPolicy)+" "),_c('span',[_vm._v(_vm._s(_vm.activeCompany.allowance_unit_is_days ? 'Days' : 'Hours ')+" ")])]),_c('div',{staticClass:"tw-py-1"},[(
              _vm.isCarryOverPolicy &&
                _vm.breakdown.policy_settings.expiry_duration_unit !== 0
            )?_c('span',{staticClass:"tw-px-3 tw-py-2 tw-rounded-full tw-text-red-500 tw-bg-red-200 tw-text-xs tw-font-semibold tw-inline-block"},[_vm._v(" Exp in "+_vm._s(_vm.breakdown.policy_settings.expiry_duration)+" "+_vm._s(_vm.policyExpiryUnit))]):_vm._e(),(
              _vm.isCarryOverPolicy &&
                _vm.breakdown.policy_settings.expiry_duration_unit === 0
            )?_c('span',{staticClass:"tw-px-3 tw-py-2 tw-rounded-full tw-text-red-500 tw-bg-red-200 tw-text-xs tw-font-semibold tw-inline-block"},[_vm._v(" No expiry")]):_vm._e()])]),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-center"},[_c('div',{staticClass:"btn btn-icon hover:tw-bg-gray-300 tw-ml-3"},[_c('div',{staticClass:"tw-inline-block tw-mt-1"},[_c('svg-icon',{staticClass:"svg-icon tw-text-gray-600",attrs:{"name":"edit-pencil"}})],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }