<template>
  <div class="tw-leading-normal" data-cy="leave-date">
    {{ leaveDate }}
  </div>
</template>

<script>
import FormatDate from './../../mixins/FormatDate'

export default {
  name: 'MyLeaveListTableRowDate',

  mixins: [FormatDate],

  props: {
    leaveBreakdown: {
      type: Object,
      required: true,
    },
    leave: {
      type: Object,
      required: true,
    },
  },

  computed: {
    leaveDate() {
      return this.formatDateFromIsoToNumberDayNumberMonthYearWithSlashAndReadableDay(
        this.toMoment(this.leaveBreakdown.from, this.leave.timezone)
      )
    },
  },
}
</script>
