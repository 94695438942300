<template>
  <tr>
    <td>{{ overtime.full_name }}</td>
    <td>{{ departmentName }}</td>
    <td>{{ employeeCode }}</td>
    <td>
      {{ overtime.amount }}
      {{ overtime.unit | pluralize(overtime.amount) }}
    </td>
    <td>
      <a
        class="tw-text-blue-500 tw-cursor-pointer hover:tw-underline"
        @click.prevent="showOvertime"
      >
        {{ date }}
      </a>
    </td>
    <td class="ld-td_whitespace-normal">{{ reason }}</td>
    <td>{{ overtime.status }}</td>
  </tr>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import FormatNumbers from '@/mixins/FormatNumbers'

export default {
  name: 'OvertimeDetailReportTableRow',

  mixins: [FormatDate, FormatNumbers],

  props: {
    overtime: {
      type: Object,
      required: true,
    },
  },

  computed: {
    departmentName() {
      if (this.overtime.department_name) {
        return this.overtime.department_name
      }

      return '-'
    },

    employeeCode() {
      return this.overtime.employee_code ? this.overtime.employee_code : '-'
    },

    reason() {
      return this.overtime.reason ? this.overtime.reason : '-'
    },

    date() {
      return this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
        this.overtime.date,
        'utc'
      )
    },
  },

  methods: {
    showOvertime() {
      this.$router.push(
        {
          query: {
            ...this.$route.query,
            overtime: this.overtime.id,
          },
        },
        () => {}
      )
    },
  },
}
</script>
