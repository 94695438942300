<template>
  <div>
    <div class="sm:tw-flex tw-justify-between tw-mb-4" data-cy="policy-form">
      <div class="tw-flex tw-justify-start tw-items-center">
        <div
          class="tw-mr-3 tw-flex tw-items-center tw-justify-center tw-rounded-full"
          :class="
            breakdown.should_deduct || breakdown.carried_over_to
              ? 'tw-bg-red-200'
              : 'tw-bg-blue-200'
          "
          style="width: 24px; height: 24px;"
        >
          <SvgIcon
            :name="
              breakdown.should_deduct || breakdown.carried_over_to
                ? 'minus'
                : 'plus'
            "
            class="svg-icon"
            :class="
              breakdown.should_deduct || breakdown.carried_over_to
                ? 'tw-text-red-500'
                : 'tw-text-blue-500'
            "
            style="width: 10px; height: 10px;"
          />
        </div>
        <div>
          <div
            class="tw-flex tw-justify-start tw-items-center"
            data-cy="policy-name"
          >
            <span
              class="tw-truncate"
              :class="[isMobileView ? 'tw-w-40' : 'tw-w-60']"
              :title="allowanceBreakdownName"
            >
              {{ allowanceBreakdownName }}
            </span>
            <span
              v-if="!isCarryOverPolicy"
              class="tw-ml-2 tw-px-2 tw-py-1 tw-rounded-full tw-text-blue-500 tw-bg-blue-200 tw-uppercase tw-font-semibold"
              style="font-size: 0.725rem;"
            >
              Per Year
            </span>
            <div
              v-if="breakdown.is_prorated"
              class="tw-ml-2 tw-px-2 tw-py-1 tw-rounded-full tw-bg-green-200 tw-uppercase tw-font-semibold tw-flex tw-items-center"
            >
              <span class="tw-text-xs tw-text-green-500">PRORATED</span>
              <button
                class="tw-ml-2 tw-text-lg tw-cursor-pointer tw-flex tw-items-center"
                @click.prevent="$emit('remove-proration', breakdown)"
              >
                <SvgIcon
                  name="close"
                  class="icon icon-close tw-w-3 tw-h-3 tw-text-red-900"
                />
              </button>
            </div>
          </div>
          <span
            class="tw-block tw-text-xs tw-leading-normal tw-truncate"
            :class="[isMobileView ? 'tw-w-40' : 'tw-w-60']"
            :title="policyUpdatedDetail"
            style="color: #8F9CB2;"
            data-cy="policy-updated-detail"
          >
            {{ policyUpdatedDetail }}
          </span>
        </div>
      </div>
      <div class="tw-flex tw-justify-end tw-items-center">
        <button
          v-if="isBreakdownLockEnabled"
          class="btn btn-icon"
          data-cy="breakdown-lock"
          @click="lockBreakdown"
        >
          <SvgIcon
            class="tw-w-5 tw-h-5"
            :class="
              breakdown.is_locked ? 'tw-text-red-500' : 'tw-text-green-500'
            "
            :name="breakdown.is_locked ? 'lock-closed' : 'lock-opened'"
          />
        </button>
        <QuickEdit
          v-if="!breakdown.carried_over_to"
          :value="breakdownAllowance"
          mode="cancel"
          button-ok-text="✓"
          button-cancel-text="✕"
          data-cy="update-allowance"
          @input="updateAllowance"
          @change-value="() => $emit('change-value', breakdown.id)"
        >
          <template v-slot:append>{{ allowanceUnit }}</template>
        </QuickEdit>
        <span v-else class="tw-p-2">
          {{ absoluteAllowance }} {{ allowanceUnit }}
        </span>
      </div>
    </div>
    <span
      v-if="errors.items[0] && errors.items[0].key === breakdown.id"
      class="tw-flex tw-justify-end tw-mb-4 tw-mr-4 tw-text-red-700"
    >
      {{ errors.items[0].error }}
    </span>
  </div>
</template>

<script>
import QuickEdit from '@/components/QuickEdit'
import FormatNumber from '@/mixins/FormatNumbers'
import ValidatesForm from '@/mixins/ValidatesForm'
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'LeaveAllowanceBreakdownInput',

  components: { QuickEdit },

  mixins: [FormatNumber, ValidatesForm, FormatDate],

  props: {
    breakdown: {
      type: Object,
      required: true,
    },

    minutesPerWorkingDay: {
      type: Number,
      required: true,
    },

    allowanceUnit: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      allowance: 0,
    }
  },

  computed: {
    absoluteAllowance() {
      return Math.abs(this.allowance)
    },

    allowanceInAllowanceUnit: {
      get() {
        return this.allowance
      },
      set(value) {
        this.allowance = Number(value)
      },
    },

    isMobileView() {
      return window.innerWidth < 760
    },

    allowanceUnitIsDays() {
      return this.allowanceUnit === 'days'
    },

    policyUpdatedDetail() {
      let policySettings = this.breakdown.allowance_breakdown_type
        .policy_settings

      if (this.breakdown.updated_by) {
        return [
          'Last updated',
          this.formatDateFromIsoToDayReadableDayNumberShortMonthYear(
            this.breakdown.updated_at,
            this.activeEmployment.timezone
          ),
          'by',
          this.breakdown.last_updated_employment.full_name,
        ].join(' ')
      }

      if (policySettings.type === 2) {
        if (this.allowance >= 0) {
          return 'Carry over from previous year'
        }

        return [
          'Unused carry over after',
          policySettings.expiry_duration,
          policySettings.expiry_duration_unit === 1 ? 'days' : 'months',
        ].join(' ')
      }

      return [
        'Last updated',
        this.formatDateFromIsoToDayReadableDayNumberShortMonthYear(
          this.breakdown.updated_at,
          this.activeEmployment.timezone
        ),
      ].join(' ')
    },

    isCarryOverPolicy() {
      return this.breakdown.allowance_breakdown_type.policy_settings.type === 2
    },

    allowanceBreakdownName() {
      if (this.breakdown.carried_over_to) {
        return 'Carry over forward into next year'
      }

      if (this.breakdown.should_deduct) {
        return 'Expired'.concat(
          ' ',
          this.breakdown.allowance_breakdown_type.name
        )
      }

      return this.breakdown.allowance_breakdown_type.name
    },

    isBreakdownLockEnabled() {
      return (
        this.activeEmployment.is_admin &&
        this.isCarryOverPolicy &&
        !this.breakdown.should_deduct &&
        !this.breakdown.carried_over_to
      )
    },

    breakdownAllowance() {
      return this.breakdown.should_deduct || this.breakdown.carried_over_to
        ? this.absoluteAllowance
        : this.allowance
    },
  },

  watch: {
    'breakdown.allowance_in_minutes': {
      immediate: true,
      handler(val) {
        this.setAllowance(val)
      },
    },
  },

  methods: {
    setAllowance(allowanceInMinutes) {
      let allowance = null

      if (this.allowanceUnitIsDays) {
        allowance = allowanceInMinutes / this.minutesPerWorkingDay
      } else {
        allowance = allowanceInMinutes / 60
      }

      this.allowance = this.toFixed(allowance, 2)
    },

    async updateAllowance(value) {
      this.allowance = Number(value)

      await this.validate()

      if (!this.valid) return

      const allowanceInMinutes = this.allowanceUnitIsDays
        ? this.allowance * this.minutesPerWorkingDay
        : this.allowance * 60

      const updatedBreakdown = {
        ...this.breakdown,
        allowance_in_minutes: allowanceInMinutes,
      }

      this.$emit('update-breakdown', updatedBreakdown)
    },

    async lockBreakdown() {
      this.$emit('lock-breakdown', this.breakdown)
    },
  },
}
</script>
