<template>
  <div
    class="tw-mx-4 tw-my-4 tw-flex tw-items-center tw-justify-between tw-text-gray-800"
    data-cy="seven-days-single-leave-block"
  >
    <div>
      <div
        class="tw-flex tw-items-center tw-justify-start"
        data-cy="seven-days-event"
      >
        <div class="user-avatar-wrap tw-flex tw-items-center tw-justify-center">
          <span class="tw--mb-1 tw-text-2xl">&#128226;</span>
        </div>
        <span class="tw-ml-2"
          >{{ workAnniversary.owner.full_name }} started
          {{ yearText }} ago.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import pluralize from 'pluralize'

export default {
  name: 'WorkAnniversary',

  props: {
    workAnniversary: {
      type: Object,
      required: true,
    },
    day: {
      type: Object,
      required: true,
    },
  },

  computed: {
    startDate() {
      return moment.utc(this.workAnniversary.start_date)
    },

    years() {
      return this.day.diff(this.startDate, 'years')
    },

    yearText() {
      return this.years === 1
        ? 'a year'
        : this.years + ' ' + pluralize('year', this.years)
    },
  },
}
</script>
