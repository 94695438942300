<template>
  <div class="tw-w-full sm:tw-w-1/2 lg:tw-w-1/3 xl:tw-w-1/4 tw-px-2">
    <div
      class="tw-mb-4 tw-p-4 tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden my-leave-calendar"
    >
      <div
        class="tw-mb-2 tw-pt-1 tw-px-2 tw-text-xl tw-font-semibold"
        v-text="month.format('MMMM')"
      />
      <FullCalendar
        v-if="employee"
        ref="calendar"
        :events="calendarEvents"
        :config="calendarConfig"
        :selectable="hasProperSubscription"
        default-view="dayGridMonth"
        class="leave-calendar"
        :class="{
          'high-contrast-mode': authUser.settings.high_contrast_mode,
        }"
        @event-created="createRequest"
        @show-overtime="overtime => this.$emit('show-overtime', overtime)"
        @show-leave="leave => this.$emit('show-leave', leave)"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import FullCalendar from '@/components/FullCalendar.vue'
import FormatDate from '@/mixins/FormatDate'
import Subscription from '@/mixins/Subscription'
import dayGridPlugin from '@fullcalendar/daygrid'
import CompanyScheduleQuery from '@/graphql/queries/company-schedule'
import EventBus from '@/plugins/event-bus'
import { groupBy } from 'lodash-es'

export default {
  name: 'MyLeaveCalendar',

  components: { FullCalendar },

  mixins: [Subscription, FormatDate],

  props: {
    month: {
      type: Object,
      required: true,
    },

    monthNumber: {
      type: Number,
      required: true,
    },

    employee: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      scheduledLeaves: [],
      scheduledOvertimes: [],
      scheduledHolidays: [],
      scheduledBirthdays: [],
      leaveLimits: [],
    }
  },

  computed: {
    calendarEvents() {
      return [
        ...this.leaves,
        ...this.overtimes,
        ...this.holidays,
        ...this.exceededLeaveLimit,
        ...this.birthday,
      ]
    },

    leaves() {
      return this.scheduledLeaves.map(leave => {
        return {
          ...leave,
          eventType: 'leave',
          start: this.getRequestStartTime(leave),
          end: this.getRequestEndTime(leave),
        }
      })
    },

    overtimes() {
      return this.scheduledOvertimes.map(overtime => {
        return {
          ...overtime,
          eventType: 'overtime',
          start: this.getRequestStartTime(overtime),
          end: this.getRequestEndTime(overtime),
        }
      })
    },

    holidays() {
      const groupedHolidays = groupBy(this.scheduledHolidays, holiday => {
        return holiday.date + holiday.type
      })

      let holidayEvents = []
      Object.values(groupedHolidays).forEach(holidays => {
        const event = holidays[0]

        const typeClass =
          event.type === 'non_working_day'
            ? 'event-holiday--non-working-day'
            : 'event-holiday--working-day'

        holidayEvents.push({
          classNames: ['event-holiday', typeClass],
          eventType: 'holiday',
          start: event.date,
          end: event.date,
          display: 'background',
          resourceIds: event.resourceIds,
          type: event.type,
          events: holidays,
        })
      })

      return holidayEvents
    },

    birthday() {
      return this.scheduledBirthdays.map(birthday => {
        return {
          ...birthday,
          classNames: ['event-birthday', 'my-leave-birthday-event'],
          eventType: 'birthday',
          resourceId: birthday.resourceIds[0],
          start: birthday.date,
          end: birthday.date,
          employmentName: this.employee.full_name,
          display: 'background',
        }
      })
    },

    exceededLeaveLimit() {
      return this.leaveLimits.map(limit => {
        return {
          ...limit,
          classNames: ['event-leave-limit-exceeded'],
          eventType: 'leave-limit',
          resourceId: limit.resourceIds[0],
          start: moment.utc(limit.start).format('YYYY-MM-DD'),
          end: moment.utc(limit.end).format('YYYY-MM-DD'),
          display: 'background',
        }
      })
    },

    calendarConfig() {
      return {
        contentHeight: 334,
        eventOverlap: false,
        fixedWeekCount: false,
        plugins: [dayGridPlugin],
        showNonCurrentDates: false,
        weekNumberCalculation: 'iso',
        initialDate: this.toDateTimeString(this.month),
        firstDay: this.activeCompany.first_day_of_week,
        businessHours: this.businessHoursOfEmployment(this.employee),
        validRange: this.validRange,
        headerToolbar: false,
        eventDisplay: 'block',
      }
    },

    validRange() {
      let start = this.month
        .clone()
        .startOf('month')
        .format('YYYY-MM-DD')
      let end = this.month
        .clone()
        .endOf('month')
        .add(1, 'day')
        .format('YYYY-MM-DD')

      if (this.isFirstMonth) {
        start = this.startDate.format('YYYY-MM-DD')
      } else if (this.isLastMonth) {
        end = this.endDate.format('YYYY-MM-DD')
      }

      return { start, end }
    },

    employmentStartDateIsNotMonthStart() {
      return (
        this.employee.start_date &&
        this.employee.align_allowance_to_start_date &&
        moment.utc(this.employee.start_date).date() !== 1
      )
    },

    startDate() {
      if (this.employmentStartDateIsNotMonthStart) {
        return moment.utc(this.employee.start_date).year(this.month.year())
      }

      return this.month.clone().startOf('month')
    },

    endDate() {
      if (this.employmentStartDateIsNotMonthStart) {
        return moment.utc(this.employee.start_date).year(this.month.year())
      }

      return this.month
        .clone()
        .endOf('month')
        .add(1, 'day')
    },

    isFirstMonth() {
      return this.monthNumber === 1
    },

    isLastMonth() {
      return this.monthNumber === 13
    },
  },

  watch: {
    month(val) {
      this.$refs.calendar.fireMethod('gotoDate', this.toDateTimeString(val))
    },

    employee() {
      this.$refs.calendar.fireMethod(
        'setOption',
        'businessHours',
        this.businessHoursOfEmployment(this.employee)
      )

      this.$refs.calendar.fireMethod('setOption', 'validRange', this.validRange)

      this.fetchCompanySchedule()
    },
  },

  created() {
    EventBus.$on(
      [
        'notification-fetched',
        'leave-requested',
        'leave-approved',
        'leave-updated',
        'leave-cancelled',
        'leave-retracted',
        'overtime-requested',
        'overtime-approved',
        'overtime-updated',
        'overtime-cancelled',
        'overtime-rejected',
      ],
      () => this.fetchCompanySchedule()
    )
  },

  beforeDestroy() {
    this.destroy()
  },

  methods: {
    getRequestStartTime(request) {
      return moment
        .utc(request.start)
        .tz(request.timezone)
        .clone()
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
    },

    getRequestEndTime(request) {
      return moment
        .utc(request.end)
        .tz(request.timezone)
        .clone()
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
    },

    destroy() {
      this.$refs.calendar.fireMethod('destroy')
    },

    businessHoursOfEmployment(employment) {
      return employment.available_working_schedule.schedule_breakdowns.map(
        breakdown => ({
          ...breakdown,
          daysOfWeek: [
            breakdown.iso_week_day === 7 ? 0 : breakdown.iso_week_day,
          ],
          startTime: breakdown.start_time,
          endTime: breakdown.end_time,
        })
      )
    },

    createRequest(e) {
      this.hideCalendarPoppers()

      this.$emit('create-request', e)
    },

    hideCalendarPoppers() {
      for (const popper of document.querySelectorAll('.tippy-popper')) {
        const instance = popper._tippy

        if (instance.state.visible) {
          instance.popperInstance.disableEventListeners()
          instance.hide()
        }
      }
    },

    fetchCompanySchedule() {
      this.$apollo.queries.companySchedule.refetch()
    },

    showLeaveDetailsPanel(leave) {
      this.$emit('show-info', leave)
    },
  },

  apollo: {
    companySchedule: {
      query: CompanyScheduleQuery,
      fetchPolicy: 'no-cache',
      variables() {
        let params = {
          company: this.activeCompany.id,
          date: this.month.format('YYYY-MM-DD'),
          interval: 'Month',
          employmentsQuery: {
            offset: 0,
            limit: 1,
            employmentIds: [this.employee.id],
            departmentIds: null,
            orderBy: null,
          },
        }

        return { ...params }
      },
      result(response) {
        const companySchedule = response.data.companySchedule

        this.scheduledLeaves = companySchedule.leave
        this.scheduledOvertimes = companySchedule.overtime
        this.scheduledHolidays = companySchedule.holidays
        this.scheduledBirthdays = companySchedule.birthdays
        this.leaveLimits = companySchedule.leaveLimits
      },
      error() {},
    },
  },
}
</script>
