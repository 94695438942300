<template>
  <div
    class="tw-mx-4 tw-my-4 tw-flex tw-items-center tw-justify-between tw-text-gray-800"
    data-cy="seven-days-single-leave-block"
  >
    <div>
      <div
        class="tw-flex tw-items-center tw-justify-start"
        data-cy="seven-days-event"
      >
        <div class="user-avatar-wrap tw-flex tw-items-center tw-justify-center">
          <span class="tw--mb-1 tw-text-2xl">&#129473;</span>
        </div>
        <span class="tw-ml-2"
          >It's {{ birthday.owner.full_name }}'s birthday!</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Birthday',

  props: {
    birthday: {
      type: Object,
      required: true,
    },
  },
}
</script>
