<template>
  <div class="tw-w-full">
    <div class="tw-px-6 tw-py-3 tw-text-lg">
      {{ proratedAllowance.name }} - currently
      {{ proratedAllowance.yearlyAllowanceInAllowanceUnit }}
      {{
        proratedAllowance.allowanceUnit
          | pluralize(proratedAllowance.yearlyAllowanceInAllowanceUnit)
      }}
    </div>
    <div class="tw-w-full tw-px-6">
      <div class="tw-flex tw-items-center tw-justify-start tw-py-3">
        <div class="tw-flex tw-justify-center tw-items-center">
          <input
            :id="'pro_rate_allowance_to_' + proratedAllowance.id"
            v-model="proratedAllowance.isProrated"
            :value="true"
            class="magic-radio"
            type="radio"
          />
          <label
            class="magic-radio-label"
            :for="'pro_rate_allowance_to_' + proratedAllowance.id"
            >Prorate allowance to</label
          >
        </div>
        <div
          class="tw-w-24 tw-mx-6 tw-border tw-border-gray-500 tw-rounded-lg tw-bg-gray-300"
        >
          <input
            v-model="proratedAllowance.allowanceInAllowanceUnit"
            class="form-control tw-py-2"
            type="number"
            step="any"
            min="0"
            :max="proratedAllowance.yearlyAllowanceInAllowanceUnit"
            :disabled="!proratedAllowance.isProrated"
          />
        </div>
        <span>{{
          proratedAllowance.allowanceUnit
            | pluralize(proratedAllowance.allowanceInAllowanceUnit)
        }}</span>
      </div>
      <div class="tw-flex tw-items-center tw-justify-start tw-m3-6 tw-py-3">
        <input
          :id="'do_not_pro_rate_allowance' + proratedAllowance.id"
          v-model="proratedAllowance.isProrated"
          :value="false"
          class="magic-radio"
          type="radio"
        />
        <label
          class="magic-radio-label"
          :for="'do_not_pro_rate_allowance' + proratedAllowance.id"
          >Do not prorate allowance</label
        >
      </div>
    </div>
  </div>
</template>

<script>
import ProratedAllowance from '@/models/employment/ProratedAllowance'

export default {
  name: 'ProrateAllowancePolicy',

  props: {
    proratedAllowance: {
      type: ProratedAllowance,
      required: true,
    },
  },

  watch: {
    'proratedAllowance.allowanceInAllowanceUnit': function() {
      this.proratedAllowance.updateAllowanceInMinutes()
    },
  },
}
</script>
