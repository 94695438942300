<template>
  <div class="tw-w-full sm:tw-w-1/2 md:tw-w-1/3 lg:tw-w-full">
    <div :class="{ 'custom-height': !hasTimeOffInLieu }" class="card tw-mb-0">
      <div class="tw-mb-5 tw-text-xl tw-font-semibold" data-cy="allowance-name">
        {{ summary.allowanceType().name }}
      </div>

      <div class="tw-leading-loose tw-text-gray-700">
        <div
          class="tw-pb-2 tw-flex tw-justify-between tw-text-gray-800"
          data-cy="total"
        >
          <div class="tw-text-lg">Total</div>

          <div class="tw-text-md">
            <div
              v-if="activeEmployment.is_admin && !hasCarryoverOrTimeOffInLieu"
            >
              <button
                class="btn btn-link tw-p-0"
                type="button"
                data-cy="show-employee-total-allowance"
                @click="
                  $emit('show-employee-allowance', summary.allowanceType())
                "
              >
                <span class="tw-text-lg">
                  {{ totalAllowance }}
                </span>

                &nbsp;

                <span class="tw-text-lg my-lv-allowance-unit">
                  {{ allowanceUnit | pluralize(totalAllowance) }}
                </span>
              </button>
            </div>

            <div v-else>
              <span class="tw-text-lg">
                {{ totalAllowance }}
              </span>
              &nbsp;
              <span class="tw-text-lg my-lv-allowance-unit">
                {{ allowanceUnit | pluralize(totalAllowance) }}
              </span>
            </div>
          </div>
        </div>

        <div class="tw-ml-5 tw-mb-3">
          <div
            v-if="hasCarryoverOrTimeOffInLieu"
            class="tw-flex tw-justify-between tw-items-center tw-text-gray-800"
            data-cy="allowance"
          >
            <div class="tw-text-lg">Allowance</div>

            <div class="tw-text-md">
              <div v-if="activeEmployment.is_admin">
                <button
                  class="btn btn-link tw-p-0"
                  type="button"
                  data-cy="show-employee-allowance"
                  @click="
                    $emit('show-employee-allowance', summary.allowanceType())
                  "
                >
                  <span class="tw-text-lg">
                    {{ summary.annualAllowance() }}
                  </span>

                  &nbsp;

                  <span class="tw-text-lg my-lv-allowance-unit">
                    {{ allowanceUnit | pluralize(summary.annualAllowance()) }}
                  </span>
                </button>
              </div>

              <div v-else>
                <span class="tw-text-lg">
                  {{ summary.annualAllowance() }}
                </span>

                &nbsp;

                <span class="tw-text-lg my-lv-allowance-unit">
                  {{ allowanceUnit | pluralize(summary.annualAllowance()) }}
                </span>
              </div>
            </div>
          </div>

          <div
            v-if="hasCarryoverBreakdowns"
            class="tw-flex tw-justify-between tw-items-center tw-text-gray-800"
            data-cy="carry-over"
          >
            <div class="tw-text-lg" data-cy="carryover-allowance-label">
              {{ carryoverAllowanceLabel }}

              <span
                v-if="!hasExpiredCarryover && carryoverExpiryDate"
                class="tw-text-sm"
              >
                (use by {{ carryoverExpiryDate }})
              </span>
            </div>

            <div class="tw-text-md" data-cy="show-employee-carryover">
              <div v-if="activeEmployment.is_admin">
                <button
                  class="btn btn-link tw-p-0"
                  type="button"
                  @click="
                    $emit('show-employee-allowance', summary.allowanceType())
                  "
                >
                  <span class="tw-text-lg">{{ totalCarryoverAllowance }}</span>
                  &nbsp;
                  <span class="tw-text-lg my-lv-allowance-unit">
                    {{ allowanceUnit | pluralize(totalCarryoverAllowance) }}
                  </span>
                </button>
              </div>

              <div v-else>
                <span class="tw-text-lg">{{ totalCarryoverAllowance }}</span>
                &nbsp;
                <span class="tw-text-lg my-lv-allowance-unit">
                  {{ allowanceUnit | pluralize(totalCarryoverAllowance) }}
                </span>
              </div>
            </div>
          </div>

          <div
            v-if="hasTimeOffInLieu"
            class="tw-flex tw-justify-between tw-items-center tw-text-gray-800"
            data-cy="timeoff-in-lieu-allowance"
          >
            <div class="tw-text-lg">Time off in lieu</div>

            <div class="tw-text-md tw-font-semibold">
              <div>
                <span class="tw-text-lg">{{ timeOffInLieuAllowance }}</span>

                &nbsp;

                <span class="tw-text-lg my-lv-allowance-unit">
                  {{ allowanceUnit | pluralize(timeOffInLieuAllowance) }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="tw-mb-2 tw-flex tw-justify-between tw-text-gray-800"
          data-cy="booked-taken"
        >
          <div class="tw-text-lg">Booked / Taken</div>

          <div class="tw-text-md">
            <div>
              <span class="tw-text-lg">{{ bookedAndTakenAllowance }}</span>

              &nbsp;

              <span class="tw-text-lg my-lv-allowance-unit">
                {{ allowanceUnit | pluralize(bookedAndTakenAllowance) }}
              </span>
            </div>
          </div>
        </div>

        <div
          class="tw-pt-2 tw-flex tw-justify-between tw-items-center tw-text-gray-800"
          data-cy="remaining"
        >
          <div class="tw-text-lg tw-font-semibold">Remaining</div>

          <div class="tw-text-md tw-font-semibold">
            <div>
              <span class="tw-text-lg">{{ remainingToBook }}</span> &nbsp;

              <span class="tw-text-lg my-lv-allowance-unit">
                {{ allowanceUnit | pluralize(remainingToBook) }}
              </span>
            </div>
          </div>
        </div>

        <div
          v-if="
            hasCarryoverBreakdowns &&
              !hasExpiredCarryover &&
              carryoverExpiryDate &&
              remainingCarryover
          "
        >
          <div
            class="tw-mt-2 tw-ml-5 tw-flex tw-justify-between tw-items-center tw-text-gray-800"
          >
            <div class="tw-text-md" data-cy="carryover-taken-by">
              To be taken by {{ carryoverExpiryDate }}
            </div>

            <div class="tw-text-md">
              <div data-cy="carryover-taken-by-allowance">
                <span class="tw-text-md">{{ remainingCarryover }}</span>

                &nbsp;

                <span class="tw-text-md my-lv-allowance-unit">
                  {{ allowanceUnit | pluralize(remainingCarryover) }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="totalCarriedAllowanceToNextYear !== 0"
          class="tw-mt-2 tw-ml-5 tw-flex tw-justify-between tw-items-center tw-text-gray-800"
        >
          <div class="tw-text-md" data-cy="label-carried-over-to-next-year">
            Carried over to next year
          </div>

          <div class="tw-text-md">
            <div data-cy="allowance-carried-to-next-year">
              <span class="tw-text-md">
                {{ totalCarriedAllowanceToNextYear }}
              </span>

              &nbsp;

              <span class="tw-text-md my-lv-allowance-unit">
                {{ allowanceUnit | pluralize(totalCarriedAllowanceToNextYear) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import FormatDate from '@/mixins/FormatDate'
import EmploymentAllowance from '@/models/reporting/EmploymentAllowance'

export default {
  name: 'LeaveAllowance',

  mixins: [FormatDate],

  props: {
    summary: {
      type: EmploymentAllowance,
      required: true,
    },

    selectedEmployment: {
      type: Object,
      required: true,
    },

    selectedCalendar: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasTimeOffInLieu() {
      return this.summary.hasTimeOffInLieu()
    },

    timeOffInLieuAllowance() {
      return this.summary.totalTimeOffInLieu()
    },

    totalAllowance() {
      return this.summary.totalAllowance()
    },

    bookedAndTakenAllowance() {
      return this.summary.totalBookedAndTakenAllowances()
    },

    remainingToBook() {
      return this.summary.remainingToBook()
    },

    accruedRemainingToBook() {
      return this.summary.accruedRemainingToBook()
    },

    allowanceUnit() {
      return this.summary.allowanceUnit().toLowerCase()
    },

    totalCarryoverAllowance() {
      return this.summary.usedCarryoverAllowance()
    },

    totalCarriedAllowanceToNextYear() {
      return this.summary.carriedAllowanceToNextYear()
    },

    remainingCarryover() {
      return this.summary.remainingCarryover()
    },

    carryoverAllowanceLabel() {
      return this.hasExpiredCarryover ? 'Used carry over' : 'Carry over'
    },

    hasExpiredCarryover() {
      return this.summary.hasExpiredCarryover()
    },

    hasCarryoverBreakdowns() {
      return this.summary.carryoverAllowance() !== 0
    },

    carryoverExpiryDate() {
      const expiryDate = this.summary.carryoverExpiryDate()

      if (!expiryDate) {
        return
      }

      if (this.selectedCalendar.start_date.year() !== expiryDate.year()) {
        return this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
          moment(expiryDate).endOf('day'),
          'utc'
        )
      }

      return this.formatDateFromIsoToDayReadableShortDayNumberShortMonth(
        moment(expiryDate).endOf('day'),
        'utc'
      )
    },

    hasCarryoverOrTimeOffInLieu() {
      return this.hasCarryoverBreakdowns || this.hasTimeOffInLieu
    },
  },
}
</script>

<style lang="scss" scoped>
@screen sm {
  .custom-height {
    min-height: 311px;
  }
}

@screen lg {
  .custom-height {
    min-height: auto;
  }
}
</style>
