<template>
  <div
    class="tw-w-full tw-mt-4 tw-p-4 tw-bg-orange-200 tw-shadow tw-rounded-lg tw-border-l-4 tw-border-orange-400 tw-text-orange-500"
  >
    <div class="tw-flex">
      <div class="tw-py-1">
        <svg-icon name="information-outline" class="tw-mr-2 tw-w-6 tw-h-6" />
      </div>
      <div>
        <p class="tw-mb-1 tw-font-semibold">Billing error</p>
        <p>
          An error has occurred taking payment. To avoid disruption, please pay
          any outstanding invoices.
        </p>
        <div class="tw-mt-2">
          <button
            class="tw-font-semibold tw-text-sm tw-text-orange-500 hover:tw-text-orange-500 tw-underline tw-cursor-pointer"
            @click="openBillingPortal"
          >
            Open billing portal
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Subscription from '@/mixins/Subscription'
import Billing from '@/api/billing/Billing'

export default {
  name: 'PaymentIssue',

  mixins: [Subscription],

  methods: {
    async openBillingPortal() {
      const { data } = await Billing.getBillingPortal(this.$route.query)
      window.location.href = data.redirect_url
    },
  },
}
</script>
