<template>
  <div
    ref="reference"
    class="tw-h-12 tw-mx-4 tw-py-2 tw-w-full tw-inline-flex tw-items-center tw-justify-between tw-no-underline tw-cursor-pointer"
  >
    <div>
      <div
        data-cy="role-switcher"
        class="tw-font-normal tw-text-base tw-select-none tw-text-gray-800"
      >
        {{ selectedRole.label }}
      </div>
      <div v-show="false" ref="tooltip">
        <div
          class="tw-w-56 tw-rounded tw-overflow-auto"
          style="width: 248px; max-height: 420px;"
          data-cy="role-switcher-list"
        >
          <div class="tw-px-4 tw-py-4 tw-text-gray-600 tw-leading-none">
            Show
          </div>
          <a
            v-for="role in roleList"
            :key="role.label"
            class="tw-flex tw-justify-start tw-items-center tw-p-4 tw-cursor-pointer tw-no-underline tw-overflow-hidden hover:tw-bg-gray-300"
            @click.prevent="selectRole(role)"
          >
            <span class="tw-font-normal" v-text="role.label" />
          </a>
        </div>
      </div>
    </div>
    <svg-icon
      name="cheveron-down"
      class="tw-ml-1 tw-w-5 tw-h-5 tw-cursor-pointer tw-text-gray-800"
    />
  </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
  name: 'RoleSwitcher',

  data: () => ({
    roleList: [
      { label: 'All Roles', value: 'ALL_ROLES' },
      { label: 'Admin', value: 'ADMIN' },
      { label: 'Approver', value: 'APPROVER' },
    ],
  }),

  computed: {
    selectedRole: {
      get() {
        if (!this.$route.query.role) {
          return this.roleList.find(role => role.value === 'ALL_ROLES')
        }

        return this.roleList.find(role => role.value === this.$route.query.role)
      },
    },
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.reference, {
      html: this.$refs.tooltip.firstChild,
      trigger: 'click',
      position: 'bottom',
      interactive: true,
      theme: 'light',
      animation: 'scale',
      inertia: true,
      arrow: true,
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },

  methods: {
    selectRole(selectedRole) {
      this.tippy.hide()
      if (selectedRole.value === 'ALL_ROLES') {
        let query = { ...this.$route.query }
        delete query.role
        this.$router.replace({ query }, () => {})
      } else {
        this.$router.push(
          {
            query: {
              ...this.$route.query,
              role: selectedRole.value,
            },
          },
          () => {}
        )
      }
    },
  },
}
</script>
