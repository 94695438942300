<template>
  <div>{{ holidayLocation }}</div>
</template>

<script>
export default {
  name: 'CompanyHolidayLocation',

  props: {
    location: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    holidayLocation() {
      if (this.location && this.location.country === '--') {
        return `${this.location.country}`
      }

      return this.location.name
    },

    locationName() {
      return this.location.name.toLowerCase()
    },
  },
}
</script>
