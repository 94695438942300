export default {
  watch: {
    $route() {
      this.redirectIfUnauthorized()
    },

    activeEmployment: {
      handler: 'redirectIfUnauthorized',
      immediate: true,
    },
  },

  methods: {
    redirectIfUnauthorized() {
      if (this.activeEmployment && this.activeEmployment.is_admin) return

      this.$router.push({ path: '/' }, () => {})
    },
  },
}
