<template>
  <table class="ld-table ld-table-clickable" data-cy="employees-table">
    <thead>
      <tr>
        <th style="width: 20px"></th>
        <th style="width: 300px">Employee Name</th>
        <th style="width: 300px">Department</th>
        <th></th>
        <th style="width: 200px" />
      </tr>
    </thead>
    <tbody>
      <template v-if="employments.length">
        <employee-table-row
          v-for="employment in employments"
          :key="employment.id"
          :employment="employment"
          :inviting-employment="invitingEmployment"
          @edit-employment="$emit('edit-employment', employment)"
          @delete-employment="$emit('delete-employment', employment)"
          @restore-employment="$emit('restore-employment', employment)"
          @remind-invitation="$emit('remind-invitation', employment)"
          @invite-employment="$emit('invite-employment', employment)"
          @send-invitation="$emit('send-invitation', employment)"
        />
      </template>
      <tr v-else-if="loading">
        <td class="tw-bg-white" colspan="4">
          <no-data message="Loading" />
        </td>
      </tr>
      <tr v-else>
        <td class="tw-bg-white" colspan="4">
          <no-data message="No employees available" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const NoData = () => import('./../NoData')
const EmployeeTableRow = () => import('./EmployeeTableRow')

export default {
  name: 'EmployeeTable',

  components: { EmployeeTableRow, NoData },

  props: {
    employments: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
    },

    invitingEmployment: {
      type: Object,
      default: null,
    },
  },
}
</script>
