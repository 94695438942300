<template>
  <div>
    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div>
            <h2 class="page-title tw-flex tw-items-center">
              <span>Bulk Data Import</span>
              <ExtraInfo icon="question" class="tw--mt-2">
                <div class="tw-p-4 tw-w-48">
                  If you are managing a large number of employees, use these
                  data import tools to upload employees, leave and allowances in
                  bulk.
                  <div>
                    <a
                      class="btn-link tw-font-semibold"
                      target="_blank"
                      href="https://help.leavedates.com/hc/en-us/articles/360002594339-How-to-bulk-upload-data"
                      >More info</a
                    >
                  </div>
                </div>
              </ExtraInfo>
            </h2>
          </div>
        </div>
      </div>

      <div class="md:tw-flex tw--mx-4">
        <EmploymentsImport />

        <LeaveImport />

        <AllowanceImport />
      </div>
    </div>
  </div>
</template>

<script>
import EmploymentsImport from '@/components/import/EmploymentsImport'
import LeaveImport from '@/components/import/LeaveImport'
import AllowanceImport from '@/components/import/AllowanceImport'

const ExtraInfo = () => import('@/components/ExtraInfo')

export default {
  name: 'Import',

  components: {
    ExtraInfo,
    EmploymentsImport,
    LeaveImport,
    AllowanceImport,
  },
}
</script>
