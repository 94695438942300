<template>
  <div class="tw-w-full">
    <div class="tw-px-6 tw-pt-6">
      <div class="tw-w-full">
        <div class="tw-mb-6 tw-py-3 tw-border tw-border-gray-500 tw-rounded-lg">
          <div class="tw-w-full tw-px-3">
            <div class="sm:tw-flex">
              <div class="tw-flex-1 tw-py-3">
                <div class="tw-full tw-px-3">
                  <div
                    class="tw-font-semibold tw-text-gray-550 tw-tracking-wider tw-uppercase"
                  >
                    Leave Year
                  </div>
                  <div
                    class="tw-mt-4 tw-text-lg tw-text-gray-800 tw-tracking-wider tw-uppercase"
                  >
                    {{ formattedCalendarYearStartDate }} -
                    {{ formattedCalendarYearEndDate }}
                  </div>
                </div>
              </div>
              <div class="tw-flex-1 tw-py-3">
                <div class="tw-full tw-px-3">
                  <div
                    class="tw-font-semibold tw-text-gray-550 tw-tracking-wider tw-uppercase"
                  >
                    {{ employment.full_name }} Works
                  </div>
                  <div
                    class="tw-mt-4 tw-text-lg tw-text-gray-800 tw-tracking-wider tw-uppercase"
                  >
                    {{ formattedEmployeeWorkYearStartDate }} -
                    {{ formattedEmployeeWorkYearEndDate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="tw-m-3">
              <div
                class="tw-p-4 tw-mt-6 tw-rounded tw-bg-green-200 tw-mt-4 tw-rounded-lg"
              >
                <div class="tw-flex-1 tw-py-3">
                  <div class="tw-full tw-px-3">
                    <div
                      class="tw-text-2xl tw-font-semibold tw-text-green-500  tw-tracking-wider tw-uppercase"
                    >
                      {{ proratedAllowanceReport.employeeWorkingPercentage }}%
                      <span class="tw-text-sm">of the year</span>
                    </div>
                  </div>
                </div>
                <div class="tw-flex-1 tw-py-3">
                  <div class="tw-full tw-px-3">
                    <div
                      class="tw-overflow-hidden tw-h-2 tw-rounded tw-bg-white"
                    >
                      <div
                        class="tw-h-2 tw-rounded tw-bg-green-500"
                        :style="{
                          width: progressIndicatorWidth,
                          marginLeft: progressIndicatorMarginLeft,
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-mb-6 tw-py-1 tw-border tw-border-gray-500 tw-rounded-lg">
          <div class="tw-px-2 tw-pb-1">
            <ProrateActionPicker
              id="action"
              class="prorate-action-picker"
              :value="selectedAction"
              :options="actions"
              tabindex="1"
              @input="actionChanged"
            />
          </div>
        </div>
        <div>
          <ProrateAllowancePolicy
            v-for="proratedAllowance in proratedAllowanceReport.allowances"
            :key="proratedAllowance.id"
            class="tw-mb-6 tw-py-3 tw-border tw-border-gray-500 tw-rounded-lg tw-bg-gray-300"
            :prorated-allowance="proratedAllowance"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import ProrateActionPicker from '@/components/pickers/ProrateActionPicker'
import ProrateAllowancePolicy from '@/components/employment-allowances/ProrateAllowancePolicy'
import ProratedAllowanceReport from '@/models/employment/ProratedAllowanceReport'

export default {
  name: 'ProrateAllowanceReport',

  components: {
    ProrateActionPicker,
    ProrateAllowancePolicy,
  },

  mixins: [FormatDate],

  props: {
    employment: {
      type: Object,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
    },
    selectedAction: {
      type: Object,
      required: true,
    },
    proratedAllowanceReport: {
      type: ProratedAllowanceReport,
      required: true,
    },
  },

  computed: {
    formattedCalendarYearStartDate() {
      return this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
        this.proratedAllowanceReport.allowanceYear.startDate
      )
    },

    formattedCalendarYearEndDate() {
      return this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
        this.proratedAllowanceReport.allowanceYear.endDate
      )
    },

    progressIndicatorWidth() {
      return this.proratedAllowanceReport.employeeWorkingPercentage + '%'
    },

    progressIndicatorMarginLeft() {
      const totalCalendarDays =
        this.proratedAllowanceReport.allowanceYear.endDate.diff(
          this.proratedAllowanceReport.allowanceYear.startDate,
          'days'
        ) + 1
      const totalEmployeeWorkingDays = this.proratedAllowanceReport.employmentWorkingPeriod.startDate.diff(
        this.proratedAllowanceReport.allowanceYear.startDate,
        'days'
      )

      return (
        ((totalEmployeeWorkingDays / totalCalendarDays) * 100).toFixed(2) + '%'
      )
    },

    formattedEmployeeWorkYearStartDate() {
      return this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
        this.proratedAllowanceReport.employmentWorkingPeriod.startDate
      )
    },

    formattedEmployeeWorkYearEndDate() {
      return this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
        this.proratedAllowanceReport.employmentWorkingPeriod.endDate
      )
    },
  },

  methods: {
    actionChanged(action) {
      this.$emit('action-changed', action)
    },
  },
}
</script>

<style>
.prorate-action-picker .multiselect__tags {
  border: none;
}
.prorate-action-picker .multiselect__single {
  font-size: 14px;
}
</style>
