<template>
  <tr @click.stop="$emit('show-info', leave)">
    <td>
      <div class="tw-flex tw-items-center tw-justify-start">
        <span
          :style="{ width: '10px', height: '10px', background: background }"
          class="tw-rounded-full tw-mr-4"
        ></span>
        <span>{{ leaveTypeName }}</span>
      </div>
    </td>
    <td data-cy="my-leave-from-date">{{ leaveFrom }}</td>
    <td>
      {{ leaveAmountInAllowanceUnit }}
      {{ allowanceUnit | pluralize(leaveAmountInAllowanceUnit) }}
    </td>
    <td>
      <RequestStatusLabel :status="leave.status" class="tw--ml-3" />
    </td>
    <td>
      <div
        class="tw-flex tw-items-center tw-justify-end"
        style="min-height: 26px;"
      >
        <button
          class="tw-ml-3"
          type="button"
          data-cy="btn-info-leave"
          @click.stop="$emit('show-info', leave)"
        >
          <svg-icon
            name="dots-horizontal-triple"
            class="tw--mr-3 tw-w-6 tw-h-5 tw-text-gray-700"
          />
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import FormatNumbers from '@/mixins/FormatNumbers'
import RequestStatusLabel from '@/components/requests/RequestStatusLabel'

export default {
  name: 'MyLeaveDateSingleTableRow',
  components: { RequestStatusLabel },

  mixins: [FormatDate, FormatNumbers],

  props: {
    leave: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      cancelling: false,
    }
  },

  computed: {
    leaveFrom() {
      return this.formatDateFromIsoToDayReadableDayNumberShortMonthYear(
        this.leave.from,
        this.leave.timezone
      )
    },

    leaveTypeName() {
      return this.leave.type.name
    },

    leaveAmount() {
      return this.leave.leave_breakdowns.reduce((prev, next) => {
        return prev + next.duration_in_minutes
      }, 0)
    },

    leaveAmountInAllowanceUnit() {
      let leaveAmount = null
      if (this.allowanceUnitIsDays) {
        leaveAmount = this.leaveAmount / this.workingMinutesPerDay
      } else {
        leaveAmount = this.leaveAmount / 60
      }

      return this.toFixed(leaveAmount, 2)
    },

    allowanceUnitIsDays() {
      return this.leave.allowance_unit_is_days
    },

    workingMinutesPerDay() {
      return this.leave.minutes_per_working_day
    },

    allowanceUnit() {
      return this.leave.allowance_unit_is_days ? 'day' : 'hour'
    },

    background() {
      return this.leave.type.colour
    },
  },

  methods: {
    handleErrors(e) {
      if (e.response.status === 404) {
        return this.$router.push('/', () => {})
      }
    },
  },
}
</script>
