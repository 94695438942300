<template>
  <div class="tw-mb-3 " data-cy="single-day-section">
    <div
      class="tw-mb-3 tw-px-4 tw-py-3 tw-text-thin tw-text-gray-800 tw-uppercase tw-bg-gray-200 tw-rounded-lg tw-border tw-border-gray-350"
      data-cy="day-of-week"
    >
      <span class="tw-mr-1">{{ dayOfWeek }}</span>
      <span class="tw-font-semibold">{{ readableTextOfDay }}</span>
    </div>
    <div class="tw-flex">
      <button
        v-show="showScrollUp"
        class="tw-bg-white tw-px-1 tw-mx-auto tw-border tw-border-blue-400 tw-rounded-full scroll-button"
        @click="scrollUp"
      >
        <SvgIcon
          class="tw-w-4 tw-h-5 tw-text-blue-500 svg-icon-up"
          name="cheveron-up"
        />
      </button>
    </div>
    <div
      :id="'day' + dayNumber"
      class="single-day-block"
      data-cy="single-days-block"
      @scroll="handleScroll"
    >
      <Holiday
        v-for="holiday in holidays"
        :key="holiday.id"
        :holiday="holiday"
      />

      <Birthday
        v-for="(birthday, i) in birthdays"
        :key="`birthday-${i}`"
        :birthday="birthday"
      />

      <WorkAnniversary
        v-for="(workAnniversary, i) in workAnniversaries"
        :key="`work-anniversary-${i}`"
        :work-anniversary="workAnniversary"
        :day="momentDay"
      />

      <Leave v-for="leave in dayLeave" :key="leave.id" :leave="leave" />
    </div>
    <div class="tw-flex">
      <button
        v-show="showScrollDown"
        class="tw-bg-white tw-px-1 tw-mx-auto tw-border tw-border-blue-400 tw-rounded-full scroll-button "
        @click="scrollDown"
      >
        <SvgIcon
          class="tw-w-4 tw-h-5 tw-text-blue-500  svg-icon-down"
          name="cheveron-down"
        />
      </button>
    </div>
  </div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import SvgIcon from '@/components/SvgIcon'

const WorkAnniversary = () =>
  import('@/components/next-seven-days/WorkAnniversary')
const Leave = () => import('@/components/next-seven-days/Leave')
const Birthday = () => import('@/components/next-seven-days/Birthday')
const Holiday = () => import('@/components/next-seven-days/Holiday')

export default {
  name: 'SingleDay',

  components: { WorkAnniversary, Birthday, Leave, Holiday, SvgIcon },

  mixins: [FormatDate],

  props: {
    dayLeave: {
      type: Array,
      required: true,
    },
    birthdays: {
      type: Array,
      required: true,
    },
    holidays: {
      type: Array,
      required: true,
    },
    workAnniversaries: {
      type: Array,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
    dayNumber: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      toggleScrollDown: true,
      toggleScrollUp: false,
    }
  },

  computed: {
    momentDay() {
      return this.toMoment(this.day, 'utc')
    },

    dayOfWeek() {
      return this.momentDay.format('dddd')
    },

    readableTextOfDay() {
      return this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
        this.day,
        'utc'
      )
    },
    eventCount() {
      return (
        this.dayLeave.length +
        this.birthdays.length +
        this.holidays.length +
        this.workAnniversaries.length
      )
    },
    showScrollDown() {
      return this.eventCount > 10 && this.toggleScrollDown
    },
    showScrollUp() {
      return this.eventCount > 10 && this.toggleScrollUp
    },
  },

  methods: {
    scrollDown() {
      const divDay = document.querySelector('#day' + this.dayNumber)
      divDay.scrollBy(0, 100)
    },

    scrollUp() {
      const divDay = document.querySelector('#day' + this.dayNumber)
      divDay.scrollBy(0, -100)
    },

    handleScroll(el) {
      if (
        el.target.offsetHeight + el.target.scrollTop >=
        el.target.scrollHeight
      ) {
        this.toggleScrollDown = false
        this.toggleScrollUp = true
      } else if (el.target.scrollTop === 0) {
        this.toggleScrollUp = false
      } else {
        this.toggleScrollDown = true
        this.toggleScrollUp = true
      }
    },
  },
}
</script>
<style scoped>
.single-day-block {
  max-height: 500px;
  overflow: auto;
  scroll-behavior: smooth;
}
.scroll-button {
  padding-left: 0.19rem;
  padding-right: 0.19rem;
}
.svg-icon-down {
  margin-top: 1px;
}
.svg-icon-up {
  margin-bottom: 1px;
}
</style>
