<template>
  <tr>
    <td data-cy="employee-name">{{ reportRow.subordinate.full_name }}</td>
    <td>{{ reportRow.subordinate.code }}</td>
    <td data-cy="approver-name">{{ reportRow.approver.full_name }}</td>
    <td>{{ reportRow.approver.code }}</td>
    <td data-cy="source-type">
      <a
        v-if="activeEmployment.is_admin"
        class="tw-text-blue-500 tw-cursor-pointer hover:tw-underline"
        @click.prevent="changeApproverSettings"
      >
        {{ source }}
      </a>
      <span v-else>{{ source }}</span>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ApproverReportTableRow',

  props: {
    reportRow: {
      type: Object,
      required: true,
    },
  },

  computed: {
    source() {
      if (this.reportRow.source === 'Department') {
        return `${this.reportRow.source} - ${this.reportRow.subordinate.department_name}`
      }

      return this.reportRow.source
    },
  },
  methods: {
    async changeApproverSettings() {
      const routeTo =
        this.reportRow.source === 'Department'
          ? {
              name: 'departments',
              params: {
                department_id: this.reportRow.subordinate.department_id,
              },
            }
          : {
              name: 'employees',
              params: { employment_id: this.reportRow.subordinate.id },
            }

      return this.$router.push(routeTo, () => {})
    },
  },
}
</script>
