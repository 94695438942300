import http from '@/plugins/http'
import FileSaver from 'file-saver'

export default class EmploymentsImport {
  static import(formData) {
    return http.post('import-employments', formData, {
      'Content-Type': 'multipart/form-data',
    })
  }

  static async downloadTemplate(params, fileName) {
    const { data } = await http.get(`import-employments`, {
      params: params,
      responseType: 'blob',
    })

    FileSaver.saveAs(new Blob([data]), fileName)
  }
}
