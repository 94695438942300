<template>
  <div
    class="tw-flex tw-flex-col tw-self-center tw-flex-1 tw-px-6 tw-pt-8 tw-pb-8 tw-text-gray-800"
  >
    <button
      class="tw-absolute tw-top-0 tw-right-0 tw-p-4"
      @click="$emit('date-pick-cancelled')"
    >
      <SvgIcon
        class="tw-w-6 tw-h-6 tw-cursor-pointer tw-text-gray-500 hover:tw-text-gray-550"
        name="close-outline"
      />
    </button>
    <div class="tw-w-full tw-px-3 tw-mt-4">
      <input
        id="future_calendar_start_date"
        v-model="calendarStartDate"
        :value="futureCalendarStartDate"
        class="magic-radio"
        name="calendar_start_date"
        type="radio"
        data-cy="radio-future-calendar"
      />
      <label
        for="future_calendar_start_date"
        class="magic-radio-label tw-w-full tw-px-3"
        >{{ futureCalendarPeriod }}</label
      >
    </div>

    <div class="tw-w-full tw-mt-4 tw-px-3">
      <input
        id="historical_calendar_start_date"
        v-model="calendarStartDate"
        class="magic-radio"
        type="radio"
        data-cy="radio-historical-calendar"
        name="calendar_start_date"
        :value="historicalCalendarStartDate"
      />
      <label for="historical_calendar_start_date" class="magic-radio-label">
        {{ historicalCalendarPeriod }}
      </label>
    </div>
    <div class="tw-mt-5 tw-w-full tw-px-3">
      <button
        class="btn btn-blue tw-shadow-lg tw-rounded-lg"
        data-cy="btn-add-calendar"
        @click="$emit('date-picked', calendarStartDate)"
      >
        Add Calendar
      </button>
    </div>
  </div>
</template>
<script>
import CalendarCollection from '@/models/company/CalendarCollection'
export default {
  name: 'NewCalendarStartDatePicker',
  props: {
    calendars: {
      type: CalendarCollection,
      required: true,
    },
  },

  data() {
    return {
      calendarStartDate: '',
    }
  },

  computed: {
    futureCalendarPeriod() {
      return this.makeCalendarPeriod(this.futureCalendarStartDate)
    },

    historicalCalendarPeriod() {
      return this.makeCalendarPeriod(this.historicalCalendarStartDate)
    },

    futureCalendarStartDate() {
      return this.calendars
        .latestCalendar()
        .end_date.clone()
        .add(1, 'day')
    },

    historicalCalendarStartDate() {
      return this.calendars
        .earliestCalendar()
        .start_date.clone()
        .subtract(1, 'year')
    },
  },

  mounted() {
    this.calendarStartDate = this.futureCalendarStartDate
  },

  methods: {
    makeCalendarPeriod(startDate) {
      const endDate = startDate
        .clone()
        .add(1, 'year')
        .subtract(1, 'day')

      return `${startDate.format('MMM YYYY')} - ${endDate.format('MMM YYYY')}`
    },
  },
}
</script>
