<template>
  <div class="tw-w-full tw-px-4">
    <div class="card">
      <div v-scroll-hint class="table-responsive">
        <table class="ld-table ld-td_ontop" data-cy="my-leave-list-view">
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="sortedLeaves.length">
              <MyLeaveListTableRow
                v-for="leave in sortedLeaves"
                :key="leave.id"
                :leave="leave"
                @show-leave="$emit('show-leave', leave)"
              />
            </template>
            <tr v-else>
              <td class="tw-bg-white" colspan="5">
                <no-data message="No leave available" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash-es'
import MyLeaveListTableRow from '@/components/my-leave-list/MyLeaveListTableRow'

const NoData = () => import('@/components/NoData')

export default {
  name: 'MyLeaveListTable',

  components: {
    MyLeaveListTableRow,
    NoData,
  },

  props: {
    dataLeaves: {
      type: Array,
      required: true,
    },
    calendar: {
      type: Object,
      required: true,
    },
  },

  computed: {
    sortedLeaves() {
      return orderBy(this.dataLeaves, ['from'])
    },
  },
}
</script>
