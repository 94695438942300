<template>
  <div class="tw-relative calendar_select-wrap">
    <v-select
      v-model="selectedCalendar"
      :options="sortedCalendars"
      :show-labels="false"
      :multiple="false"
      :allow-empty="false"
      :max-height="180"
      :custom-label="formattedCalenderDate"
      placeholder="Select a calendar"
      data-cy="calendar-select"
      track-by="id"
    >
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-multiselect'
import { sortBy } from 'lodash-es'
import FormatDate from '../../mixins/FormatDate'

export default {
  name: 'CalendarPicker',

  components: { vSelect },

  mixins: [FormatDate],

  props: {
    value: {
      type: Object,
      required: true,
    },

    calendars: {
      type: Array,
      required: true,
    },
  },

  computed: {
    sortedCalendars() {
      return sortBy(this.calendars, 'start_date')
    },

    selectedCalendar: {
      get() {
        return this.value
      },
      set(selectedCalendar) {
        this.$emit('input', selectedCalendar)
      },
    },
  },

  methods: {
    formattedCalenderDate(calendar) {
      return (
        this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
          calendar.start_date,
          'utc'
        ) +
        ' - ' +
        this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
          calendar.end_date,
          'utc'
        )
      )
    },
  },
}
</script>
