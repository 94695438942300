<template>
  <div
    class="tw-mx-4 tw-my-4 tw-flex tw-items-center tw-justify-between tw-text-gray-800"
    data-cy="seven-days-single-leave-block"
  >
    <div>
      <div
        class="tw-flex tw-items-center tw-justify-start"
        data-cy="seven-days-event"
      >
        <div>
          <user-avatar :user="leave.owner" class="tw-mr-2" />
        </div>
        <div class="tw-flex tw-flex-col">
          <span data-cy="leave-owner">{{ leave.owner.full_name }}</span>
          <span data-cy="leave-type" class="tw-text-gray-700">{{
            leave.type.name
          }}</span>
        </div>
      </div>
    </div>
    <div>
      <div
        class="tw-flex tw-items-center tw-justify-end"
        data-cy="seven-days-leaves-duration"
      >
        <div class="tw-flex tw-flex-col tw-text-right">
          <span data-cy="duration-in-words">{{ durationInWord }}</span>
          <span class="tw-text-gray-700">{{ sessionInWord }}</span>
        </div>
        <span
          :style="{
            width: '10px',
            height: '10px',
            background: leave.type.colour,
          }"
          class="tw-rounded-full tw-ml-4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'Leave',

  mixins: [FormatDate],

  props: {
    leave: {
      type: Object,
      required: true,
    },
  },

  computed: {
    durationInWord() {
      if (this.isEmptyLeave) return ''

      if (!this.allowanceUnitIsDays || !this.durationMatchesAvailableWordings) {
        return this.leavePeriod()
      }

      return this.leave.matchedLeaveDate.period.split(' - ')[0]
    },

    sessionInWord() {
      if (
        this.isEmptyLeave ||
        !this.allowanceUnitIsDays ||
        !this.durationMatchesAvailableWordings
      ) {
        return ''
      }

      return this.leave.matchedLeaveDate.period.split(' - ')[1]
    },

    isEmptyLeave() {
      return !this.leave.matchedLeaveDate.duration_in_minutes
    },

    allowanceUnitIsDays() {
      return this.leave.allowance_unit_is_days
    },

    durationMatchesAvailableWordings() {
      const durationWordings = [
        'All day',
        'Half day - Morning',
        'Half day - Afternoon',
      ]

      return durationWordings.some(word => {
        return word === this.leave.matchedLeaveDate.period
      })
    },
  },

  methods: {
    leavePeriod() {
      return (
        this.formatDateFromIsoToHourMinutes(
          this.leave.matchedLeaveDate.from,
          this.leave.timezone
        ) +
        ' - ' +
        this.formatDateFromIsoToHourMinutes(
          this.leave.matchedLeaveDate.to,
          this.leave.timezone
        )
      )
    },
  },
}
</script>
