<template>
  <svg :class="{ [`icon-${name}`]: name }" class="icon">
    <use :xlink:href="`#${name}`" />
  </svg>
</template>

<script>
const files = require.context(
  '!svg-sprite-loader!./../assets/icons',
  false,
  /.*\.svg$/
)

files.keys().forEach(files)

export default {
  name: 'SvgIcon',

  props: {
    name: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
</style>
