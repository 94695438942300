import http from '@/plugins/http'

export default class Billing {
  static async getBillingDetails(params) {
    return http.get(`billing/details`, {
      params: params,
    })
  }

  static getRenewSubscription(params) {
    return http.get(`billing/renew-subscription`, {
      params: params,
    })
  }

  static getBillingPortal(params) {
    return http.get(`billing/portal`, {
      params: params,
    })
  }
}
