<template>
  <button
    type="button"
    class="tw-w-full"
    @click.stop="$emit('show-edit-breakdown-form', breakdown)"
  >
    <div class="tw-flex tw-justify-between form-control">
      <div class="tw-flex tw-items-center tw-justify-start">
        <div
          class="tw-truncate tw-mr-3"
          :title="breakdown.name"
          style="max-width: 10.5rem;"
        >
          {{ breakdown.name }}
        </div>
        <span
          class="tw-px-3 tw-py-2 tw-mr-3 tw-rounded-full tw-uppercase tw-text-xs tw-font-semibold"
          :class="
            isCarryOverPolicy
              ? 'tw-text-orange-500 tw-bg-orange-200'
              : 'tw-text-blue-500 tw-bg-blue-200'
          "
        >
          {{ policyLabel }}
        </span>
      </div>
      <div class="tw-flex tw-items-center tw-justify-end">
        <div class="sm:tw-flex tw-border-r tw-border-gray-300 tw-pr-3">
          <div class="tw-px-3 tw-py-2">
            {{ defaultAmountInDaysByPolicy }}
            <span
              >{{
                activeCompany.allowance_unit_is_days ? 'Days' : 'Hours '
              }}&nbsp;</span
            >
          </div>
          <div class="tw-py-1">
            <span
              v-if="
                isCarryOverPolicy &&
                  breakdown.policy_settings.expiry_duration_unit !== 0
              "
              class="tw-px-3 tw-py-2 tw-rounded-full tw-text-red-500 tw-bg-red-200 tw-text-xs tw-font-semibold tw-inline-block"
            >
              Exp in {{ breakdown.policy_settings.expiry_duration }}
              {{ policyExpiryUnit }}</span
            >
            <span
              v-if="
                isCarryOverPolicy &&
                  breakdown.policy_settings.expiry_duration_unit === 0
              "
              class="tw-px-3 tw-py-2 tw-rounded-full tw-text-red-500 tw-bg-red-200 tw-text-xs tw-font-semibold tw-inline-block"
            >
              No expiry</span
            >
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-justify-center">
          <div class="btn btn-icon hover:tw-bg-gray-300 tw-ml-3">
            <div class="tw-inline-block tw-mt-1">
              <svg-icon name="edit-pencil" class="svg-icon tw-text-gray-600" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import FormatNumbers from './../../mixins/FormatNumbers'
import ValidatesForm from './../../mixins/ValidatesForm'

export default {
  name: 'AllowanceBreakdown',

  mixins: [FormatNumbers, ValidatesForm],

  props: {
    dataBreakdown: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      breakdown: {
        ...this.dataBreakdown,
      },
    }
  },

  computed: {
    defaultAmountInDays: {
      get() {
        const defaultAmount = this.activeCompany.allowance_unit_is_days
          ? this.breakdown.amount_in_minutes /
            this.breakdown.minutes_per_working_day
          : this.breakdown.amount_in_minutes / 60

        return this.toFixed(defaultAmount, 2)
      },
      set(value) {
        this.breakdown.minutes_per_working_day = this.activeCompany.minutes_per_working_day

        this.breakdown.amount_in_minutes =
          value *
          (this.activeCompany.allowance_unit_is_days
            ? this.breakdown.minutes_per_working_day
            : 60)
      },
    },

    isCarryOverPolicy() {
      return this.breakdown.policy_settings.type === 2
    },

    policyLabel() {
      if (this.isCarryOverPolicy) {
        return 'Carry Over'
      }
      return 'Per Year'
    },

    policyExpiryUnit() {
      switch (this.breakdown.policy_settings.expiry_duration_unit) {
        case 1:
          return 'Days'
        case 2:
          return 'Months'
        default:
          return 'Never Expires'
      }
    },

    defaultAmountInDaysByPolicy() {
      if (this.isCarryOverPolicy) {
        return 'Max'.concat(' ', this.defaultAmountInDays)
      }

      return this.defaultAmountInDays
    },
  },

  watch: {
    dataBreakdown: {
      immediate: true,
      handler(breakdown) {
        this.breakdown = breakdown
      },
    },
  },
}
</script>
