<template>
  <div>
    <div
      class="tw-flex tw-justify-center tw-items-center tw-min-h-screen tw-bg-gray-200 tw-p-4 ie_tw-h-screen"
    >
      <div class="tw-w-full w-360">
        <div class="tw-shadow-md tw-rounded">
          <form
            class="tw-bg-white tw-px-8 tw-pt-6 tw-pb-6"
            @submit.prevent="authenticate"
          >
            <div>
              <a :href="marketingSiteUrl" title="Click to visit">
                <HeaderLogo />
              </a>
            </div>
            <p
              class="tw-font-semibold tw-mb-4 tw-mt-8 tw-text-2xl tw-text-gray-800"
            >
              Sign In
            </p>

            <div
              v-if="errorMessage.content"
              class="tw-flex tw-justify-start tw-items-center tw-mt-4 tw-mb-4 tw-text-red-500 tw-font-semibold"
              :class="[
                errorMessage.type === 'warning'
                  ? 'tw-text-orange-600'
                  : 'tw-text-red-500',
              ]"
            >
              <SvgIcon
                style="width: 14px; height: 14px;"
                class="tw-mr-2"
                name="exclamation-solid"
              />
              <div>{{ errorMessage.content }}</div>
            </div>

            <div class="tw-mb-4">
              <label class="form-label" for="username">
                Username
              </label>
              <input
                id="username"
                v-model="username"
                v-validate.disable="'required|email'"
                v-focus
                name="username"
                data-vv-as="username"
                data-cy="username"
                class="form-control"
                type="email"
                placeholder="john@example.com"
                autocomplete="off"
                inputmode="email"
              />
              <p
                v-show="errors.has('username')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('username') }}
              </p>
            </div>
            <div class="tw-mb-1">
              <label class="form-label" for="password">
                Password
              </label>
              <input
                id="password"
                v-model="password"
                v-validate.disable="'required'"
                name="password"
                class="form-control"
                type="password"
                placeholder="Password"
                autocomplete="current-password"
                data-cy="password"
              />
              <p
                v-show="errors.has('password')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('password') }}
              </p>
              <RouterLink
                :to="{ name: 'forgot-password' }"
                class="tw-mt-4 tw-inline-block btn-link btn-link_focus tw-font-semibold tw-text-sm tw-align-baseline tw-underline"
                data-cy="forgot-password"
              >
                Forgot Password?
              </RouterLink>
            </div>

            <div class="tw-mt-3 tw-flex tw-items-center tw-justify-between">
              <SpinnerButton
                :disabled="loading"
                :loading="loading"
                :spinner-only="true"
                class="tw-mr-3"
                type="submit"
                data-cy="btn-sign-in"
              >
                Sign In
              </SpinnerButton>

              <RouterLink
                :event="!loading ? 'click' : ''"
                :to="{ name: 'signup' }"
                class="tw-mx-0 btn btn-borderless btn-link btn-link_focus tw-text-blue-500"
              >
                Create Company
              </RouterLink>
            </div>
          </form>
          <RouterLink
            :event="!loading ? 'click' : ''"
            :to="{ name: 'company-domain' }"
            class="tw-bg-blue-200 hover:tw-bg-blue-100 tw-flex tw-justify-center tw-mb-4 tw-py-4 tw-text-blue-500 tw-font-semibold"
          >
            Sign in with SSO
          </RouterLink>
        </div>

        <AuthFooter />
      </div>
    </div>
    <a
      href="https://bwr2j9ibei.execute-api.us-east-1.amazonaws.com/ProdStage/"
      rel="nofollow"
      style="display: none"
      aria-hidden="true"
      >Link</a
    >
  </div>
</template>

<script>
import AuthFooter from '@/components/AuthFooter'
import SpinnerButton from '@/components/SpinnerButton'
import ValidatesForm from '@/mixins/ValidatesForm'
import MarketingSiteUrl from '@/mixins/MarketingSiteUrl'
import HandleSingleSignOn from '@/mixins/HandleSingleSignOn'
import HeaderLogo from '@/components/HeaderLogo'

export default {
  name: 'SignIn',

  middleware: 'guest',

  layout: 'DefaultLayout',

  components: { HeaderLogo, AuthFooter, SpinnerButton },

  mixins: [ValidatesForm, MarketingSiteUrl, HandleSingleSignOn],

  data() {
    return {
      username: '',
      password: '',
      loading: false,
      errorMessage: { type: null, content: '' },
    }
  },
  mounted() {
    const { reason } = this.$route.query

    if (reason === 'session_timeout') {
      this.errorMessage = {
        type: 'warning',
        content: 'Your session has expired. Please sign in again',
      }
    }

    if (this.$route.params.message) {
      this.errorMessage = this.$route.params.message
    }

    this.showRecaptchaBadge()
  },

  methods: {
    async showRecaptchaBadge() {
      await this.$recaptchaLoaded()

      this.$recaptchaInstance.showBadge()
    },

    async authenticate() {
      try {
        this.resetError()

        await this.validate()

        if (!this.valid) return

        this.loading = true

        const recaptchaToken = await this.$recaptcha('login')

        await this.login({
          email: this.username,
          password: this.password,
          'g-recaptcha-response': recaptchaToken,
        })
      } catch ({ response }) {
        if (response && response.status === 422) {
          this.errorMessage = {
            type: 'error',
            content: response.data.errors.sso_enforced
              ? response.data.errors.sso_enforced
              : 'The username or password is incorrect.',
          }
        }
      }

      this.loading = false
    },
    resetError() {
      this.errorMessage = this.$options.data().errorMessage
    },
  },
}
</script>
