<template>
  <div>
    <h2 class="tw-mt-2 tw-mb-6 tw-text-gray-800">
      {{ employmentAllowance.allowanceType().name }}
    </h2>
    <div>
      <div class="tw-flex-1 tw-mb-3" data-cy="progress-bar-section">
        <div
          class="progress-bar tw-w-full tw-rounded-full tw-overflow-hidden tw-h-4"
          data-cy="leave-progress-bar"
        >
          <div
            :style="{ width: takenPercentage + '%' }"
            class="tw-inline-block tw-h-4 tw-bg-purple-600"
            data-cy="progress-bar-taken"
          ></div>
          <div
            :style="{ width: bookedPercentage + '%' }"
            class="tw-inline-block tw-h-4 tw-bg-pink-500"
            data-cy="progress-bar-booked"
          ></div>
          <div
            :style="{ width: remainingPercentage + '%' }"
            class="tw-inline-block tw-h-4 tw-bg-indigo-500"
            data-cy="progress-bar-remaining"
          ></div>
        </div>
      </div>
      <!-- Larger Screens -->
      <div class="tw-hidden sm:tw-flex">
        <div class="tw-flex-1" data-cy="leaves-amount">
          <div
            v-if="employmentAllowance.takenAllowance()"
            :style="{ minWidth: '8%', width: leftLabelPercentage + '%' }"
            class="tw-inline-block tw-h-4"
          >
            <div class="tw-w-full" data-cy="taken-amount">
              <span class="tw-font-semibold tw-text-xs md:tw-text-xl">
                {{ employmentAllowance.takenAllowance() }}</span
              >&nbsp;<span class="tw-font-normal tw-text-xs md:tw-text-base">
                {{
                  allowanceUnit
                    | pluralize(employmentAllowance.takenAllowance())
                }}
              </span>
            </div>
            <div class="tw-w-full">
              <span
                class="tw-font-semibold tw-text-purple-600 tw-text-xs md:tw-text-base"
                >taken</span
              >
            </div>
          </div>
          <div
            v-if="employmentAllowance.bookedAllowance()"
            :style="{ minWidth: '8%', width: middleLabelPercentage + '%' }"
            class="tw-inline-block tw-h-4"
          >
            <div class="tw-w-full" data-cy="booked-amount">
              <span class="tw-font-semibold tw-text-xs md:tw-text-xl">
                {{ employmentAllowance.bookedAllowance() }}</span
              >&nbsp;<span class="tw-font-normal tw-text-xs md:tw-text-base">
                {{
                  allowanceUnit
                    | pluralize(employmentAllowance.bookedAllowance())
                }}
              </span>
            </div>
            <div class="tw-w-full">
              <span
                class="tw-font-semibold tw-text-pink-500 tw-text-xs md:tw-text-base"
                >booked</span
              >
            </div>
          </div>
          <div
            v-if="employmentAllowance.remainingToBook()"
            class="tw-inline-block tw-h-4"
            style="min-width: 8%"
          >
            <div class="tw-w-full" data-cy="remaining-amount">
              <span class="tw-font-semibold tw-text-xs md:tw-text-xl">
                {{ employmentAllowance.remainingToBook() }}</span
              >&nbsp;<span class="tw-font-normal tw-text-xs md:tw-text-base">
                {{
                  allowanceUnit
                    | pluralize(employmentAllowance.remainingToBook())
                }}
              </span>
            </div>
            <div class="tw-w-full">
              <span
                class="tw-font-semibold tw-text-indigo-500 tw-text-xs md:tw-text-base"
                >remaining</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile Screens -->
      <div class="sm:tw-hidden tw-mb-3">
        <div class="tw-flex tw-items-center tw-justify-between">
          <div v-if="employmentAllowance.takenAllowance()">
            <div>
              <span class="tw-font-semibold tw-text-base"
                >{{ employmentAllowance.takenAllowance() }}
                {{
                  allowanceUnit
                    | pluralize(employmentAllowance.takenAllowance())
                }}</span
              >
              &nbsp;
              <span class="tw-font-semibold tw-text-base tw-text-purple-600"
                >taken</span
              >
            </div>
          </div>
          <div v-if="employmentAllowance.bookedAllowance()">
            <div>
              <span class="tw-font-semibold tw-text-base"
                >{{ employmentAllowance.bookedAllowance() }}
                {{
                  allowanceUnit
                    | pluralize(employmentAllowance.bookedAllowance())
                }}</span
              >
              &nbsp;
              <span class="tw-font-semibold tw-text-base tw-text-pink-500"
                >booked</span
              >
            </div>
          </div>
          <div v-if="employmentAllowance.remainingToBook()">
            <div>
              <span class="tw-font-semibold tw-text-base"
                >{{ employmentAllowance.remainingToBook() }}
                {{
                  allowanceUnit
                    | pluralize(employmentAllowance.remainingToBook())
                }}</span
              >
              &nbsp;
              <span class="tw-font-semibold tw-text-base tw-text-indigo-500"
                >remaining</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmploymentAllowance from '@/models/reporting/EmploymentAllowance'

export default {
  name: 'AllowanceSummaryChart',

  props: {
    employmentAllowance: {
      type: EmploymentAllowance,
      required: true,
    },
  },

  computed: {
    allowanceUnit() {
      return this.employmentAllowance.allowanceUnit().toLowerCase()
    },

    takenPercentage() {
      return (
        (this.employmentAllowance.takenAllowance() /
          this.employmentAllowance.totalAllowance()) *
        100
      )
    },

    bookedPercentage() {
      return (
        (this.employmentAllowance.bookedAllowance() /
          this.employmentAllowance.totalAllowance()) *
        100
      )
    },

    remainingPercentage() {
      return (
        (this.employmentAllowance.remainingToBook() /
          this.employmentAllowance.totalAllowance()) *
        100
      )
    },

    leftLabelPercentage() {
      const maxPercentage = 84

      return this.takenPercentage > maxPercentage
        ? maxPercentage
        : this.takenPercentage
    },

    middleLabelPercentage() {
      const maxPercentage = 92 - this.takenPercentage

      return this.bookedPercentage > maxPercentage
        ? maxPercentage
        : this.bookedPercentage
    },
  },
}
</script>
