<template>
  <div>
    <allowance-breakdown
      v-show="!showEditBreakdownForm"
      :data-breakdown="dataBreakdown"
      data-cy="btn-allowance-breakdown"
      @show-edit-breakdown-form="showBreakdownForm"
    />

    <AllowanceBreakdownInput
      v-show="showEditBreakdownForm"
      ref="input"
      :data-breakdown="dataBreakdown"
      :has-carry-over-allowance="hasCarryOverAllowance"
      :storing="breakdownStoring"
      :deleting="breakdownDeleting"
      @cancel="hideBreakdownForm"
      @edit-breakdown="editBreakdown"
      @drop-breakdown="dropBreakdown"
      @breakdown-editing="$emit('breakdown-editing')"
    />
  </div>
</template>

<script>
import AllowanceBreakdown from './AllowanceBreakdown'
import AllowanceBreakdownInput from './AllowanceBreakdownInput'

export default {
  name: 'AllowanceBreakdownWrapper',

  components: {
    AllowanceBreakdown,
    AllowanceBreakdownInput,
  },

  props: {
    dataBreakdown: {
      type: Object,
      required: true,
    },

    hasCarryOverAllowance: {
      type: Boolean,
      required: true,
    },

    shouldHideEditBreakdownForm: {
      type: Boolean,
      default: false,
    },

    breakdownStoring: {
      type: Boolean,
      required: true,
    },

    breakdownDeleting: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      showEditBreakdownForm: false,
    }
  },

  watch: {
    shouldHideEditBreakdownForm(value) {
      if (value) {
        this.hideBreakdownForm()
      }
    },
  },

  methods: {
    addBreakdown(breakdown) {
      this.$emit('add-breakdown', breakdown)
    },

    editBreakdown(breakdown) {
      this.$emit('edit-breakdown', breakdown)
    },

    dropBreakdown(breakdown) {
      this.$emit('drop-breakdown', breakdown)
    },

    showBreakdownForm(breakdown) {
      this.showEditBreakdownForm = true

      this.$emit('edit-breakdown-form-open', breakdown)
    },

    hideBreakdownForm() {
      this.showEditBreakdownForm = false

      this.$emit('edit-breakdown-form-close')
    },
  },
}
</script>
