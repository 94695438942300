<template>
  <div :title="title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DisabledElementWithTitle',

  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
