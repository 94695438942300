<template>
  <div class="tw-flex-1 tw-mb-3" data-cy="progress-bar-section">
    <div
      class="progress-bar tw-w-full tw-rounded-full tw-overflow-hidden tw-h-4"
      data-cy="leave-progress-bar"
    >
      <div
        :style="{ width: usedPercentage + '%' }"
        class="tw-inline-block tw-h-4 tw-bg-blue-600"
        data-cy="progress-bar-taken"
      ></div>
      <div
        :style="{ width: freePercentage + '%' }"
        class="tw-inline-block tw-h-4 tw-bg-gray-300"
        data-cy="progress-bar-booked"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillingUsageChart',
  props: {
    limit: {
      type: Number,
      required: true,
    },
    used: {
      type: Number,
      required: true,
    },
  },

  computed: {
    usedPercentage() {
      return Math.floor((this.$props.used / this.$props.limit) * 100)
    },
    freePercentage() {
      return 100 - this.usedPercentage
    },
  },
}
</script>
