<template>
  <div>
    <nav data-cy="settings-nav">
      <div class="navbar-secondary navbar-items">
        <RouterLink
          :to="{ name: 'company' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="settings-company"
        >
          <SvgIcon
            name="library"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />

          <span>Company</span>
        </RouterLink>

        <RouterLink
          :to="{ name: 'departments' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="settings-departments"
        >
          <SvgIcon
            name="travel-case"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />

          <span>Departments</span>
        </RouterLink>

        <RouterLink
          :to="{ name: 'allowances' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="settings-allowances"
        >
          <SvgIcon
            name="check-circle-solid"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />

          <span>Allowances</span>
        </RouterLink>

        <RouterLink
          :to="{ name: 'overtime' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="settings-overtime"
        >
          <SvgIcon
            name="clock"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />

          <span>Overtime</span>
        </RouterLink>

        <RouterLink
          :to="{ name: 'leave-types' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline tw-whitespace-no-wrap"
          data-cy="settings-leave-types"
        >
          <SvgIcon
            name="color-palette"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />

          <span>Leave Types</span>
        </RouterLink>

        <RouterLink
          :to="{ name: 'holidays' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="settings-holidays"
        >
          <SvgIcon
            name="globe"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />

          <span>Holidays</span>
        </RouterLink>

        <RouterLink
          :to="{ name: 'employees' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="settings-employees"
        >
          <SvgIcon
            name="user-group"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />

          <span>Employees</span>
        </RouterLink>

        <RouterLink
          :to="{ name: 'calendars' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="settings-calendars"
        >
          <SvgIcon
            name="calendar"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />

          <span>Calendars</span>
        </RouterLink>

        <RouterLink
          :to="{ name: 'limits' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="settings-limits"
        >
          <SvgIcon
            name="tachometer"
            class="tw-mr-2 tw-w-5 tw-h-5 tw-text-gray-600"
          />

          <span>Limits</span>
        </RouterLink>

        <RouterLink
          :to="{ name: 'import' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline tw-whitespace-no-wrap"
          data-cy="settings-import"
        >
          <SvgIcon
            name="cloud-upload"
            class="tw-mr-2 tw-w-5 tw-h-5 tw-text-gray-600"
          />

          <span>Data Import</span>
        </RouterLink>

        <RouterLink
          :to="{ name: 'integrations' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
        >
          <SvgIcon name="code" class="tw-mr-2 tw-w-5 tw-h-5 tw-text-gray-600" />

          <span>Integrations</span>
        </RouterLink>

        <RouterLink
          :to="{ name: 'billing' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="settings-upgrade"
        >
          <SvgIcon
            v-if="!isSubscriptionActive"
            name="rocket-f"
            class="tw-mr-2 tw-w-5 tw-h-5 tw-text-gray-600"
          />

          <SvgIcon
            v-if="isSubscriptionActive"
            name="credit-card"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />

          <span>{{ billingName }}</span>
        </RouterLink>
      </div>
    </nav>
  </div>
</template>

<script>
import Subscription from '@/mixins/Subscription'
import { mapActions } from 'vuex'

export default {
  name: 'SettingsNavbar',

  mixins: [Subscription],

  ...mapActions('auth', ['fetchUser']),

  computed: {
    billingName() {
      return this.isSubscribed ? 'Billing' : 'Upgrade'
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar-secondary {
  @screen lg {
    @apply .w-auto;
  }
}
</style>
