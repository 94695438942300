<template>
  <button
    type="button"
    class="tw-w-full tw-mb-4"
    @click.stop="$emit('edit', allowance)"
  >
    <div
      class="tw-flex tw-justify-between form-control tw-py-4"
      data-cy="allowance-name-button"
    >
      <div class="tw-flex tw-items-center tw-justify-start">
        <div
          class="tw-truncate"
          :title="allowance.allowance_type.name"
          style="max-width: 15rem;"
        >
          {{ allowance.allowance_type.name }}
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-justify-end">
        <div
          class="tw-border-r tw-border-gray-300 tw-pr-3"
          data-cy="allowance-value-button"
        >
          <span v-if="!is_unlimited"
            >{{ totalAllowancesInUnits }} {{ allowanceUnit }}</span
          >
          <span v-if="is_unlimited" class="tw-flex tw-items-center">
            <svg-icon
              name="infinity"
              class="tw-w-6 tw-h-4 tw-text-blue-500 svg-icon tw-mr-2"
            />
            Unlimited
          </span>
        </div>
        <div class="tw-flex tw-items-center tw-justify-center">
          <div class="btn btn-icon hover:tw-bg-gray-300 tw-ml-3">
            <div class="tw-inline-block tw-mt-1">
              <svg-icon name="edit-pencil" class="svg-icon tw-text-gray-600" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import FormatNumber from '../../mixins/FormatNumbers'

export default {
  name: 'LeaveAllowance',

  mixins: [FormatNumber],

  props: {
    allowance: {
      type: Object,
      required: true,
    },

    hideCarriedToNextAllowance: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      is_unlimited: false,
    }
  },

  computed: {
    allowanceBreakdowns() {
      if (this.hideCarriedToNextAllowance) {
        return this.allowance.breakdowns.filter(breakdown => {
          return !breakdown.carried_over_to
        })
      }

      return this.allowance.breakdowns
    },

    totalAllowancesInUnits() {
      let totalAllowance = null

      if (this.allowanceUnitIsDays) {
        totalAllowance = this.totalAllowances / this.minutesPerWorkingDay
      } else {
        totalAllowance = this.totalAllowances / 60
      }

      return this.toFixed(totalAllowance, 2)
    },

    allowanceUnitIsDays() {
      return this.allowance.allowance_unit_is_days
    },

    totalAllowances() {
      return this.allowanceBreakdowns
        .map(item => {
          return parseInt(item.allowance_in_minutes) || 0
        })
        .reduce((total, current) => total + current, 0)
    },

    minutesPerWorkingDay() {
      return this.allowance.minutes_per_working_day
    },

    allowanceUnit() {
      return this.allowanceUnitIsDays ? 'days' : 'hours'
    },
  },

  watch: {
    allowance: {
      immediate: true,
      handler(allowance) {
        this.is_unlimited = allowance.is_unlimited
      },
    },
  },

  created() {
    this.is_unlimited = this.allowance.is_unlimited
  },
}
</script>
