<template>
  <div>
    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div>
            <h2 class="page-title tw-flex tw-items-center">
              <span>Overtime: Time Off In Lieu (TOIL)</span>

              <ExtraInfo icon="question" class="tw--mt-2">
                <div class="tw-p-4 tw-w-48">
                  Time off in lieu is the paid time off an employee gets,
                  instead of overtime pay, for having worked additional hours.
                  <div>
                    <a
                      class="btn-link tw-font-semibold"
                      target="_blank"
                      :href="overtimeDocumentationUrl"
                    >
                      More info
                    </a>
                  </div>
                </div>
              </ExtraInfo>
            </h2>
          </div>
        </div>
      </div>

      <div class="tw-w-full md:tw-w-1/2">
        <TimeOffInLieu :allowance-types="allowances" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TimeOffInLieu from '@/components/time-off-in-lieu/TimeOffInLieu'
import documentationUrls from '@/documentations/documentation-urls'

const ExtraInfo = () => import('@/components/ExtraInfo')

export default {
  name: 'Overtime',

  components: { TimeOffInLieu, ExtraInfo },

  computed: {
    ...mapState('allowances', {
      allowances: 'list',
    }),

    overtimeDocumentationUrl() {
      return documentationUrls.overtimeUrl
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.getAllAllowances()
      },
    },
  },

  methods: {
    ...mapActions('allowances', {
      getAllAllowances: 'all',
    }),
  },
}
</script>
