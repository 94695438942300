<template>
  <p class="tw-mb-4 tw-text-gray-700">
    <SvgIcon :name="icon" class="tw-ml-1 tw-mr-2 tw-w-4 tw-h-4" />
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'TextWithIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>
