<template>
  <div>
    <div
      class="tw-w-full tw-mt-4 tw-p-4 tw-bg-orange-200 tw-shadow tw-rounded-lg tw-border-l-4 tw-border-orange-400 tw-text-orange-500"
      data-cy="account-verification-notification"
    >
      <div class="tw-flex">
        <div class="tw-py-1">
          <svg-icon name="information-outline" class="tw-mr-2 tw-w-6 tw-h-6" />
        </div>
        <div>
          <p class="tw-mb-1 tw-font-semibold">Verify Your Account.</p>
          <p>
            To finish setting up this Leave Dates account, we just need to make
            sure that the email address is yours. Please click the link in the
            verification email to verify your account.

            <BaseLink
              theme="orange"
              class="tw-font-semibold tw-text-sm"
              :underline="true"
              @click="resendVerificationCode"
            >
              Resend
            </BaseLink>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLink from '@/components/BaseLink'

export default {
  name: 'AccountVerification',

  components: { BaseLink },

  methods: {
    async resendVerificationCode() {
      try {
        await this.$http.post('resend-verification')

        this.success('Your verification code has been sent.')
      } catch (e) {
        this.error('We are having some issues to send the verification code.')
      }
    },
  },
}
</script>
