<template>
  <div>
    <div
      class="tw-w-full tw-mt-4 tw-p-4 tw-bg-orange-200 tw-shadow tw-rounded-lg tw-border-l-4 tw-border-orange-400 tw-text-orange-500"
    >
      <div class="tw-flex">
        <div class="tw-py-1">
          <svg-icon name="information-outline" class="tw-mr-2 tw-w-6 tw-h-6" />
        </div>
        <div>
          <p class="tw-mb-1 tw-font-semibold">Current plan limit exceeded.</p>
          <p>
            You have more users than allowed on the current plan. Please upgrade
            your plan or reduce the number of users to below the subscription
            limit ({{ numberOfSeats }}). It will not be possible to request
            leave until you do this.
            <a
              class="tw-font-semibold tw-text-sm tw-text-orange-500 hover:tw-text-orange-500 tw-underline tw-cursor-pointer"
              @click="upgrade"
            >
              Upgrade
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Subscription from '../mixins/Subscription'

export default {
  name: 'NeedsSubscription',

  mixins: [Subscription],

  methods: {
    upgrade() {
      this.$router.push(
        {
          name: 'billing',
        },
        () => {}
      )
    },
  },
}
</script>
