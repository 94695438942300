<template>
  <div>
    <nav data-cy="settings-nav">
      <div class="navbar-secondary navbar-items tw-whitespace-no-wrap">
        <router-link
          :to="{ name: 'reports-employee' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="employee-report"
        >
          <svg-icon
            name="user-group"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />
          <span>Employees</span>
        </router-link>
        <router-link
          :to="{ name: 'reports-leave' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="leave-report"
        >
          <svg-icon
            name="color-palette"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />
          <span>Leave</span>
        </router-link>
        <router-link
          :to="{ name: 'reports-allowances' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="allowance-report"
        >
          <svg-icon
            name="check-circle-solid"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />
          <span>Allowances</span>
        </router-link>
        <router-link
          v-if="isOvertimeEnabled"
          :to="{ name: 'reports-overtime' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="toil-report"
        >
          <svg-icon
            name="clock"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />
          <span>Overtime</span>
        </router-link>
        <router-link
          :to="{ name: 'reports-employment-holidays' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="public-holiday-report"
        >
          <svg-icon
            name="globe"
            class="tw-mr-2 tw-w-4 tw-h-4 tw-text-gray-600"
          />
          <span>Public Holidays</span>
        </router-link>
        <router-link
          :to="{ name: 'reports-approver' }"
          class="lg:tw-w-full lg:tw-mb-3 tw-px-3 tw-py-3 tw-flex tw-items-center tw-font-normal tw-text-base tw-text-gray-800 hover:tw-underline"
          data-cy="approvers-report"
        >
          <svg-icon
            name="approver"
            class="tw-mr-2 tw-w-5 tw-h-5 tw-text-gray-600"
          />
          <span>Approver</span>
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'ReportsNavbar',

  computed: {
    isOvertimeEnabled() {
      return !!this.activeCompany.current_time_off_in_lieu
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar-secondary {
  @screen lg {
    @apply .w-auto;
  }
}
</style>
