<template>
  <button
    type="button"
    class="tw-w-full form-control"
    @click.stop="$emit('show-edit-form')"
  >
    <div class="tw-flex tw-justify-between tw-items-center">
      <div
        class="tw-truncate tw-mr-3 tw-justify-start"
        :title="allowance.name"
        style="max-width: 15rem;"
      >
        {{ allowance.name }}
      </div>
      <div class="tw-flex tw-justify-center">
        <div class="btn btn-icon hover:tw-bg-gray-300 tw-ml-3">
          <div class="tw-inline-block tw-mt-1">
            <svg-icon name="edit-pencil" class="svg-icon tw-text-gray-600" />
          </div>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-justify-start">
      <div
        class="tw-truncate tw-mr-3 tw-mt-3 tw-text-gray-700"
        :title="allowance.description"
        style="max-width: 15rem;"
      >
        {{ allowance.description }}
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'AllowanceSummary',

  props: {
    allowance: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped></style>
