<template>
  <tr @click.stop="$emit('edit-department')">
    <td data-cy="td-department-name">{{ department.name }}</td>
    <td>
      <div class="tw-flex tw-items-center tw-justify-end">
        <button
          class="btn btn-icon tw-border tw-border-gray-400 hover:tw-bg-gray-300 tw-ml-3"
          title="Edit"
          data-cy="edit-department"
          @click.stop="$emit('edit-department')"
        >
          <div class="tw-flex tw-items-center tw-justify-center">
            <svg-icon
              name="edit-pencil"
              class="svg-icon tw-w-3 tw-h-3 tw-text-gray-700"
            />
          </div>
        </button>
        <button
          class="btn btn-icon tw-border tw-bg-red-500 hover:tw-bg-red-500 tw-border-red-500 tw-ml-3"
          title="Delete"
          data-cy="delete-department"
          @click.stop="$emit('delete-department')"
        >
          <div class="tw-flex tw-items-center tw-justify-center">
            <svg-icon
              name="trash"
              class="svg-icon tw-w-3 tw-h-3 tw-text-white"
            />
          </div>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'DepartmentTableRow',

  props: {
    department: {
      type: Object,
      required: true,
    },
  },
}
</script>
