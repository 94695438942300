<template>
  <transition name="page" mode="out-in">
    <router-view />
  </transition>
</template>

<script>
import AdminAccess from '@/mixins/AdminAccess'

export default {
  name: 'Settings',

  middleware: 'auth',

  mixins: [AdminAccess],
}
</script>
