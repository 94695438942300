<template>
  <tr>
    <td class="tw-font-semibold" data-cy="leave-list-row-date">
      <MyLeaveListTableRowDate
        v-for="breakdown in leave.leave_breakdowns"
        :key="breakdown.id"
        :leave-breakdown="breakdown"
        :leave="leave"
      />
    </td>
    <td data-cy="leave-list-row-time">
      <MyLeaveListTableRowTime
        v-for="breakdown in leave.leave_breakdowns"
        :key="breakdown.id"
        :leave-breakdown="breakdown"
        :leave="leave"
        :working-minutes-per-day="workingMinutesPerDay"
      />
    </td>
    <td>
      <div class="tw-flex tw-items-center" data-cy="leave-list-row-leave-name">
        <span
          :style="`width: 10px; height: 10px; background: ${leaveTypeColour}`"
          class="tw-rounded-full tw-mr-2"
        ></span>
        <span
          class="btn btn-link tw-p-0"
          data-cy="leave-type-name"
          @click="$emit('show-leave')"
          >{{ leaveTypeName }}</span
        >
        <span v-if="isRequestedLeave" class="tw-ml-1">(pending approval)</span>
      </div>
    </td>
    <td data-cy="leave-list-row-amount">
      {{ leaveAmountInAllowanceUnit }}
      {{ allowanceUnit | pluralize(leaveAmountInAllowanceUnit) }}
    </td>
    <td data-cy="leave-list-row-leavenote">{{ leaveNote }}</td>
  </tr>
</template>

<script>
import FormatNumbers from '@/mixins/FormatNumbers'
import MyLeaveListTableRowDate from '@/components/my-leave-list/MyLeaveListTableRowDate'
import MyLeaveListTableRowTime from '@/components/my-leave-list/MyLeaveListTableRowTime'

export default {
  name: 'MyLeaveListTableRow',

  components: { MyLeaveListTableRowDate, MyLeaveListTableRowTime },

  mixins: [FormatNumbers],

  props: {
    leave: {
      type: Object,
      required: true,
    },
  },

  computed: {
    leaveTypeName() {
      return this.leave.type.name
    },

    leaveTypeColour() {
      return this.leave.type.colour
    },

    leaveAmount() {
      return this.leave.leave_breakdowns.reduce((prev, next) => {
        return prev + next.duration_in_minutes
      }, 0)
    },

    allowanceUnit() {
      return this.leave.allowance_unit_is_days ? 'day' : 'hour'
    },

    leaveAmountInAllowanceUnit() {
      let amount = null

      if (this.leave.allowance_unit_is_days) {
        amount = this.leaveAmount / this.workingMinutesPerDay
      } else {
        amount = this.leaveAmount / 60
      }

      return this.toFixed(amount, 2)
    },

    workingMinutesPerDay() {
      return this.leave.minutes_per_working_day
    },

    leaveNote() {
      return this.leave.reason
    },

    isRequestedLeave() {
      return this.leave.status === 'REQUESTED'
    },
  },
}
</script>
