<template>
  <div>
    <LeaveAllowance
      v-show="!showGroupInputForm"
      :allowance="allowance"
      :hide-carried-to-next-allowance="hideCarriedToNextAllowance"
      @edit="showGroupForm"
    />

    <LeaveAllowanceGroupInput
      v-show="showGroupInputForm"
      :value="[allowance]"
      :allowance="allowance"
      :employment="employment"
      :hide-carried-to-next-allowance="hideCarriedToNextAllowance"
      @close="showGroupInputForm = false"
      @is-unlimited-changed="$emit('is-unlimited-changed')"
      @update-breakdown="$emit('update-breakdown', $event)"
      @remove-proration="$emit('remove-proration', $event)"
      @employment-allowance-updated="$emit('employment-allowance-updated')"
      @change-value="key => $emit('change-value', key)"
    />
  </div>
</template>

<script>
import LeaveAllowanceGroupInput from '@/components/employee-leave-allowance/LeaveAllowanceGroupInput'
import LeaveAllowance from '@/components/employee-leave-allowance/LeaveAllowance'

export default {
  name: 'LeaveAllowanceWrapper',

  components: { LeaveAllowance, LeaveAllowanceGroupInput },

  props: {
    allowance: {
      type: Object,
      required: true,
    },

    employment: {
      type: Object,
      required: true,
    },

    selectedAllowanceType: {
      type: Object,
      default: null,
    },

    allowanceIndex: {
      type: Number,
      required: true,
    },

    hideCarriedToNextAllowance: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showGroupInputForm: false,
    }
  },

  watch: {
    selectedAllowanceType: {
      immediate: true,
      handler(value) {
        if (!value && this.allowanceIndex === 0) {
          this.showGroupInputForm = true
          return
        }

        if (!value) {
          return
        }

        if (value.id === this.allowance.allowance_type.id) {
          this.showGroupInputForm = true
          return
        }

        this.showGroupInputForm = false
      },
    },
  },

  methods: {
    showGroupForm(breakdown) {
      this.showGroupInputForm = true

      this.$emit('edit-group-input-form', breakdown)
    },
  },
}
</script>
