<template>
  <div>
    <div class="tw-mt-3 tw-p-3" data-cy="employment-allowance-wrap">
      <div v-if="employment" class="tw-p-3 tw-pt-0">
        <div v-if="selectedCalendar" class="employment-allowance-wrap">
          <div class="form-group">
            <div class="tw-w-full tw-mb-6 md:tw-mb-0">
              <label class="form-label" for="calendar"> Calendar </label>
              <CalendarPicker
                id="calendar"
                v-model="selectedCalendar"
                :calendars="calendars"
              />
            </div>
          </div>
          <template v-if="employmentAllowances.length">
            <div class="tw-w-full">
              <p class="form-label">Allowances</p>

              <div v-if="canShowEmploymentNotWorkingFullYearWarning">
                <div class="tw-w-full tw-mb-6">
                  <div
                    class="tw-bg-orange-200 tw-bg-opacity-50 tw-border tw-border-orange-400 tw-rounded-lg
                     tw-overflow-hidden tw-text-orange-500"
                  >
                    <div class="tw-py-4 tw-px-2 tw-flex tw-items-center">
                      <div class="tw-mx-2">
                        <SvgIcon
                          name="exclamation-warning-triangle-solid"
                          class="icon icon-close tw-w-6 tw-h-6 tw-text-gray-700"
                        />
                      </div>
                      <div class="tw-mx-2">
                        <div>
                          {{ employment.full_name }} {{ tense }} not
                          {{ isCurrentCalendar ? 'working' : 'work' }} the full
                          year from {{ formattedCalendarYearStartDate }} -
                          {{ formattedCalendarYearEndDate }}
                        </div>
                        <button
                          class="tw-font-semibold tw-border-b tw-border-orange-500 focus:tw-border-orange-500"
                          data-cy="calculate-prorated-allowances"
                          @click.prevent="
                            $emit('show-proration-modal', prorationData)
                          "
                        >
                          {{
                            hasProratedBreakdowns ? 'Recalculate' : 'Calculate'
                          }}
                          prorated allowances
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <LeaveAllowanceWrapper
                v-for="(allowance, index) in employmentAllowances"
                :key="allowance.id"
                :allowance="allowance"
                :allowance-index="index"
                :employment="employment"
                :hide-carried-to-next-allowance="hideCarriedToNextAllowance"
                :selected-allowance-type="selectedAllowanceType"
                data-cy="leave-allowance-wrapper"
                @is-unlimited-changed="$emit('employment-allowance-updated')"
                @employment-allowance-updated="
                  $emit('employment-allowance-updated')
                "
                @update-breakdown="updateBreakdown"
                @remove-proration="removeProration"
                @edit-group-input-form="editGroupInput"
                @change-value="key => clearErrors(key)"
              />
            </div>
          </template>
          <NoData v-else :message="allowancesNotAvailableMessage" />
        </div>
        <NoData v-else :message="calendarsNotAvailableMessage" />
      </div>

      <div v-else class="tw-pb-4">
        <NoData />
      </div>
    </div>
  </div>
</template>

<script>
import { flatMap, sortBy } from 'lodash-es'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import moment from 'moment-timezone'
import { Allowances } from '@/api'

const NoData = () => import('@/components/NoData')
const LeaveAllowanceWrapper = () =>
  import('@/components/employee-leave-allowance/LeaveAllowanceWrapper')
const CalendarPicker = () =>
  import('@/components/calendar-picker/CalendarPicker')

export default {
  name: 'EmployeeLeaveAllowances',

  components: {
    NoData,
    CalendarPicker,
    LeaveAllowanceWrapper,
  },

  mixins: [FormatDate, ValidatesForm],

  props: {
    employment: {
      type: Object,
      required: true,
    },
    calendars: {
      type: Array,
      required: true,
    },
    currentCalendar: {
      type: Object,
      default: null,
    },
    hideCarriedToNextAllowance: {
      type: Boolean,
      default: false,
    },
    currentAllowanceType: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      employmentAllowances: [],
      selectedCalendar: this.currentCalendar,
      selectedAllowanceType: this.currentAllowanceType,
    }
  },

  computed: {
    isActiveEmployment() {
      return this.employment && this.employment.id === this.activeEmployment.id
    },

    calendarsNotAvailableMessage() {
      if (!this.employment) {
        return
      }

      if (this.isActiveEmployment) {
        return 'You have no calendars available.'
      }

      return this.employment.full_name + ' has no calendars available.'
    },

    allowancesNotAvailableMessage() {
      if (!this.employment) {
        return
      }

      if (this.isActiveEmployment) {
        return 'You have no allowances available.'
      }

      return this.employment.full_name + ' has no allowances available.'
    },

    isPastCalendar() {
      const today = moment().utc(true)

      return (
        this.selectedCalendar &&
        this.selectedCalendar.end_date.isBefore(today, 'date')
      )
    },

    isCurrentCalendar() {
      const today = moment().utc(true)

      return (
        this.selectedCalendar &&
        today.isBetween(
          this.selectedCalendar.start_date,
          this.selectedCalendar.end_date
        )
      )
    },

    isFutureCalendar() {
      const today = moment().utc(true)

      return (
        this.selectedCalendar &&
        this.selectedCalendar.start_date.isAfter(today, 'date')
      )
    },

    tense() {
      if (this.isPastCalendar) {
        return 'did'
      }

      if (this.isFutureCalendar) {
        return 'will'
      }

      return 'is'
    },

    formattedCalendarYearStartDate() {
      return this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
        this.selectedCalendar.start_date
      )
    },

    formattedCalendarYearEndDate() {
      return this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
        this.selectedCalendar.end_date
      )
    },

    momentStartDate() {
      if (!this.employment.start_date) {
        return null
      }

      return moment.utc(this.employment.start_date)
    },

    momentEndDate() {
      if (!this.employment.end_date) {
        return null
      }

      return moment.utc(this.employment.end_date)
    },

    employmentStartDateWithingTheCalendar() {
      return (
        this.momentStartDate &&
        this.withinCalendar(this.selectedCalendar, this.momentStartDate)
      )
    },

    employmentEndDateWithingTheCalendar() {
      return (
        this.momentEndDate &&
        this.withinCalendar(this.selectedCalendar, this.momentEndDate)
      )
    },

    isStartDateAvailableForProrate() {
      return (
        this.employment.start_date &&
        !this.employment.align_allowance_to_start_date &&
        this.employmentStartDateWithingTheCalendar
      )
    },

    isEndDateAvailableForProrate() {
      return this.employmentEndDateWithingTheCalendar
    },

    isEmploymentStartOrEndDateWithingTheCalendar() {
      return (
        this.employmentStartDateWithingTheCalendar ||
        this.employmentEndDateWithingTheCalendar
      )
    },

    isEmploymentStartOrEndDateEqualsToTheCalendarStartOrEnd() {
      if (
        this.isEmploymentStartDateEqualsToTheCalendarStart &&
        !this.employmentEndDateWithingTheCalendar
      ) {
        return true
      }

      return this.isEmploymentEndDateEqualsToTheCalendarEnd
    },

    isEmploymentStartDateEqualsToTheCalendarStart() {
      return (
        this.momentStartDate &&
        this.momentStartDate.isSame(this.selectedCalendar.start_date, 'date')
      )
    },

    isEmploymentEndDateEqualsToTheCalendarEnd() {
      return (
        this.momentEndDate &&
        this.momentEndDate.isSame(this.selectedCalendar.end_date, 'date')
      )
    },

    canShowEmploymentNotWorkingFullYearWarning() {
      if (!this.hasBreakdownsToProrate) {
        return false
      }

      if (this.isEmploymentStartOrEndDateEqualsToTheCalendarStartOrEnd) {
        return false
      }

      if (!this.employment.align_allowance_to_start_date) {
        return this.isEmploymentStartOrEndDateWithingTheCalendar
      }

      if (this.momentEndDate) {
        return (
          this.withinCalendar(this.selectedCalendar, this.momentEndDate) &&
          !this.selectedCalendar.end_date.isSame(this.momentEndDate, 'date')
        )
      }

      return false
    },

    prorationData() {
      return {
        startDateChanged: this.isStartDateAvailableForProrate,
        startDate: this.momentStartDate,
        endDateChanged: this.isEndDateAvailableForProrate,
        endDate: this.momentEndDate,
        startCalendar: this.isStartDateAvailableForProrate
          ? this.selectedCalendar
          : null,
        endCalendar: this.isEndDateAvailableForProrate
          ? this.selectedCalendar
          : null,
      }
    },

    dateOfBirth() {
      if (this.form.date_of_birth instanceof moment) {
        return this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
          this.form.date_of_birth
        )
      }
      return this.form.date_of_birth
    },

    calendarAllowances() {
      return sortBy(
        this.employment.allowances.filter(
          allowance => allowance.calendar_id === this.selectedCalendar.id
        ),
        'allowance_type.name'
      )
    },

    hasBreakdownsToProrate() {
      const allowances = this.calendarAllowances.filter(
        allowance => !allowance.is_unlimited
      )

      return flatMap(allowances, 'breakdowns').some(breakdown => {
        return (
          breakdown.yearly_allowance_in_minutes &&
          breakdown.allowance_breakdown_type.policy_settings.type === 1
        )
      })
    },

    hasProratedBreakdowns() {
      return flatMap(this.calendarAllowances, 'breakdowns').some(
        breakdown => breakdown.is_prorated
      )
    },
  },

  watch: {
    employment() {
      this.employmentAllowances = [...this.calendarAllowances]
    },

    calendarAllowances(val) {
      if (!val || !val.length) {
        this.employmentAllowances = []
      }

      this.employmentAllowances = [...val]
    },
  },

  mounted() {
    this.employmentAllowances = [...this.calendarAllowances]
  },

  methods: {
    clearErrors() {
      this.errors.remove('allowance_in_minutes')
    },

    async updateBreakdown(breakdown) {
      try {
        await Allowances.updateEmploymentAllowanceBreakdown(breakdown, {
          allowance_in_minutes: breakdown.allowance_in_minutes,
          company_id: this.activeCompany.id,
        })

        this.$emit('employment-allowance-updated')

        this.success('Allowance updated successfully.')
      } catch ({ response }) {
        this.errors.add({
          key: breakdown.id,
          field: 'allowance_in_minutes',
          error: response.data.errors.allowance_in_minutes[0],
        })

        this.serverError()
      }
    },

    editGroupInput(allowance) {
      this.selectedAllowanceType = allowance.allowance_type
    },

    async removeProration(breakdown) {
      try {
        await Allowances.updateEmploymentAllowanceBreakdown(breakdown, {
          company_id: this.activeCompany.id,
          is_prorated: false,
        })

        this.$emit('employment-allowance-updated')

        this.success('Proration removed successfully.')
      } catch ({ response }) {
        this.serverError()
      }
    },

    withinCalendar(calendar, date) {
      return date
        .clone()
        .utc(true)
        .isBetween(
          moment.utc(calendar.start_date),
          moment.utc(calendar.end_date),
          'date',
          '[]'
        )
    },
  },
}
</script>

<style lang="scss" scoped>
@screen sm {
  .ld-tab-container {
    min-height: 671px;

    .employment-allowance-wrap {
      min-height: 606px;
    }
  }
}
</style>
