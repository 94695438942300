<template>
  <div class="tw-leading-normal" data-cy="leave-time">
    {{ leaveTime }}
  </div>
</template>

<script>
import FormatDate from './../../mixins/FormatDate'

export default {
  name: 'MyLeaveListTableRowTime',

  mixins: [FormatDate],

  props: {
    leaveBreakdown: {
      type: Object,
      required: true,
    },
    leave: {
      type: Object,
      required: true,
    },
    workingMinutesPerDay: {
      type: Number,
      required: true,
    },
  },

  computed: {
    leaveFromTime() {
      return this.formatDateFromIsoToHourMinutes(
        this.toMoment(this.leaveBreakdown.from, this.leave.timezone)
      )
    },

    leaveToTime() {
      return this.formatDateFromIsoToHourMinutes(
        this.toMoment(this.leaveBreakdown.to, this.leave.timezone)
      )
    },

    leaveTime() {
      if (this.leaveAmount === this.workingMinutesPerDay) {
        return 'All day'
      }

      return `${this.leaveFromTime} - ${this.leaveToTime}`
    },

    leaveAmount() {
      return this.leaveBreakdown.duration_in_minutes
    },
  },
}
</script>
