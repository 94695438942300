<template>
  <transition name="page" mode="out-in">
    <router-view />
  </transition>
</template>

<script>
export default {
  name: 'Reports',

  middleware: 'auth',

  watch: {
    $route() {
      this.redirectIfUnauthorized()
    },

    activeEmployment: {
      handler: 'redirectIfUnauthorized',
      immediate: true,
    },
  },

  methods: {
    redirectIfUnauthorized() {
      if (
        this.activeEmployment &&
        (this.activeEmployment.is_admin || this.activeEmployment.is_approver)
      )
        return

      this.$router.push({ path: '/' }, () => {})
    },
  },
}
</script>
