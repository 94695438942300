<template>
  <div>
    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div><h2 class="page-title">My Notifications</h2></div>
          <div>
            <SpinnerButton
              :disabled="loading"
              :loading="loading"
              :spinner-only="true"
              @click="markAllNotificationsAsRead"
            >
              Mark All as Read
            </SpinnerButton>
          </div>
        </div>
      </div>

      <div v-if="notifications.length">
        <DynamicNotification
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
          :active-notification="activeNotification"
          :class="[notification.isRead() ? 'tw-bg-white' : 'tw-bg-gray-300']"
          class="notification-list"
          @toggle-read="toggleRead"
          @click="markNotificationAsRead"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NotificationList from '@/components/notifications/mixins/NotificationList'
import SpinnerButton from '@/components/SpinnerButton'
const DynamicNotification = () =>
  import('@/components/notifications/DynamicNotification')

export default {
  name: 'Notifications',

  middleware: 'auth',

  components: {
    DynamicNotification,
    SpinnerButton,
  },

  mixins: [NotificationList],

  data: () => ({
    loading: false,
  }),

  methods: {
    markNotificationAsRead(notification) {
      this.markAsActive(notification)

      this.markAsRead(notification)

      this.navigate(notification)
    },

    async markAllNotificationsAsRead() {
      this.loading = true

      await this.markAllAsRead()

      this.loading = false
    },

    navigate(notification) {
      notification.navigate(this.$router, this.$route.query)
    },
  },
}
</script>
