import { render, staticRenderFns } from "./ProrateAllowancePolicy.vue?vue&type=template&id=09dc6b42"
import script from "./ProrateAllowancePolicy.vue?vue&type=script&lang=js"
export * from "./ProrateAllowancePolicy.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports