<template>
  <div
    class="tw-mx-4 tw-my-4 tw-flex tw-items-center tw-justify-between tw-text-gray-800"
    data-cy="seven-days-single-leave-block"
  >
    <div>
      <div
        class="tw-flex tw-items-center tw-justify-start"
        data-cy="seven-days-event"
      >
        <span
          class="user-avatar-wrap tw-overflow-hidden tw-rounded-full event-holiday"
          :class="typeClass"
        ></span>
        <div class="tw-flex tw-flex-col tw-ml-2">
          <span>{{ holiday.name }} - {{ holiday.location }}</span>
          <span class="tw-text-gray-700">{{ type }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Holiday',

  props: {
    holiday: {
      type: Object,
      required: true,
    },
  },

  computed: {
    typeClass() {
      return this.isNonWorkingDay
        ? 'event-holiday--non-working-day'
        : 'event-holiday--working-day'
    },

    type() {
      return this.isNonWorkingDay ? 'Non-working day' : 'Working day'
    },

    isNonWorkingDay() {
      return this.holiday.type === 'non_working_day'
    },
  },
}
</script>
