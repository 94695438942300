<template>
  <div>
    <div class="modal-header">
      <div class="tw-flex tw-justify-between tw-items-center">
        <div>
          <div class="modal-title">
            <div class="tw-text-2xl tw-font-normal tw-text-white">
              {{ employment.full_name }}'s {{ headerTitleDates }} date has
              changed
            </div>

            <div class="tw-flex tw-items-center tw-mt-2">
              <div class="tw-text tw-font-medium tw-text-white">
                Choose whether to prorate allowance
              </div>

              <a
                :href="prorationUrl"
                target="_blank"
                class="tw-flex tw-items-center tw-ml-4"
              >
                <SvgIcon
                  name="question"
                  class="icon icon-close tw-w-4 tw-h-4 tw-text-white tw-opacity-50"
                />
              </a>
            </div>
          </div>
        </div>

        <div>
          <button
            class="modal-close tw-right-0"
            data-cy="close-employee-proration"
            @click.prevent="$emit('close-proration-modal')"
          >
            <SvgIcon name="close" class="icon icon-close tw-w-4 tw-h-4" />
          </button>
        </div>
      </div>
    </div>

    <div v-if="calendar" class="tw-flex tw-flex-col">
      <div
        v-if="isLoading"
        class="tw-py-20 tw-m-6 tw-border tw-border-gray-500 tw-bg-gray-300 tw-rounded-lg tw-flex tw-justify-center"
      >
        <SvgIcon
          name="spinner-circle"
          class="tw-animate-spin tw-h-12 tw-w-12 tw-text-gray-500"
        />
      </div>

      <form
        v-if="selectedAction && !isLoading && proratedAllowanceReport"
        class="tw-w-full"
        @submit.prevent="saveBreakdowns"
      >
        <ProrateAllowanceReport
          :employment="employment"
          :actions="actions"
          :selected-action="selectedAction"
          :prorated-allowance-report="proratedAllowanceReport"
          @action-changed="setAction"
        />

        <div class="tw-flex tw-justify-end tw-px-6 tw-pb-6">
          <SpinnerButton
            :disabled="isSaving"
            :loading="isSaving"
            :spinner-only="true"
            :spinner-classes="['tw-h-2 tw-w-2 tw-text-white']"
            class="btn btn-blue"
            type="submit"
            cypress-attribute="btn-save-prorate-allowance"
            tabindex="18"
          >
            Save
          </SpinnerButton>
        </div>
      </form>
    </div>

    <div v-else class="tw-w-full">
      <div
        class="tw-px-8 tw-pt-6 tw-pb-3 tw-text-xl tw-text-gray-700 tw-tracking-wider"
      >
        <div class="tw-pb-3">
          The {{ headerTitleDates }} date chosen is outside of the calendar
          dates of the company.
        </div>

        <div class="tw-pb-3">
          Therefore, {{ employment.full_name }} will not have allowances to
          prorate.
        </div>
      </div>

      <div class="tw-flex tw-justify-end tw-px-6 tw-pb-6">
        <SpinnerButton
          class="btn btn-blue"
          data-cy="btn-ok-proration"
          @click.prevent="$emit('close-proration-modal')"
        >
          Ok
        </SpinnerButton>
      </div>
    </div>
  </div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import FormatNumbers from '@/mixins/FormatNumbers'
import { filter, first, flatMap } from 'lodash-es'
import ProrateAllowanceReport from '@/components/employment-allowances/ProrateAllowanceReport'
import documentationUrls from '@/documentations/documentation-urls'
import { Allowances } from '@/api'
import SpinnerButton from '@/components/SpinnerButton'

const DO_NOT_PRORATE_ANY_ALLOWANCES = 'DO_NOT_PRORATE_ANY_ALLOWANCES'
const ROUND_TO_THE_NEAREST_DAY = 'ROUND_TO_THE_NEAREST_DAY'
const ROUND_TO_THE_NEAREST_HALF_DAY = 'ROUND_TO_THE_NEAREST_HALF_DAY'
const ROUND_TO_ONE_DECIMAL_PLACE = 'ROUND_TO_ONE_DECIMAL_PLACE'
const ROUND_TO_THE_NEAREST_HOUR = 'ROUND_TO_THE_NEAREST_HOUR'
const ROUND_UP_TO_THE_NEAREST_DAY = 'ROUND_UP_TO_THE_NEAREST_DAY'
const ROUND_UP_TO_THE_NEAREST_HALF_DAY = 'ROUND_UP_TO_THE_NEAREST_HALF_DAY'
const ROUND_UP_TO_ONE_DECIMAL_PLACE = 'ROUND_UP_TO_ONE_DECIMAL_PLACE'
const ROUND_UP_TO_THE_NEAREST_HOUR = 'ROUND_UP_TO_THE_NEAREST_HOUR'
const ROUND_DOWN_TO_THE_NEAREST_DAY = 'ROUND_DOWN_TO_THE_NEAREST_DAY'
const ROUND_DOWN_TO_THE_NEAREST_HALF_DAY = 'ROUND_DOWN_TO_THE_NEAREST_HALF_DAY'
const ROUND_DOWN_TO_ONE_DECIMAL_PLACE = 'ROUND_DOWN_TO_ONE_DECIMAL_PLACE'
const ROUND_DOWN_TO_THE_NEAREST_HOUR = 'ROUND_DOWN_TO_THE_NEAREST_HOUR'
const UK_GUIDELINES = 'UK_GUIDELINES'

export default {
  name: 'ProrateAllowanceModal',

  components: {
    SpinnerButton,
    ProrateAllowanceReport,
  },

  mixins: [FormatDate, FormatNumbers],

  props: {
    employment: {
      type: Object,
      required: true,
    },
    startDate: {
      type: Object,
      default: null,
    },
    startDateChanged: {
      type: Boolean,
      default: false,
    },
    startCalendar: {
      type: Object,
      default: null,
    },
    endDate: {
      type: Object,
      default: null,
    },
    endDateChanged: {
      type: Boolean,
      default: false,
    },
    endCalendar: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      defaultAction: {
        id: ROUND_UP_TO_THE_NEAREST_HALF_DAY,
        name: 'Round up to the nearest half day',
      },
      selectedAction: null,
      dataAllowanceBreakdowns: [],
      proratedAllowanceReport: null,
      isLoading: true,
      isSaving: false,
    }
  },

  computed: {
    prorationUrl() {
      return documentationUrls.prorationUrl
    },

    actions() {
      let actions = []
      actions.push(
        {
          id: DO_NOT_PRORATE_ANY_ALLOWANCES,
          name: 'Do not prorate any allowances',
        },
        {
          id: UK_GUIDELINES,
          name: 'UK Guidelines',
        },
        {
          id: ROUND_TO_THE_NEAREST_DAY,
          name: 'Round to the nearest day',
        },
        {
          id: ROUND_TO_THE_NEAREST_HALF_DAY,
          name: 'Round to the nearest half day',
        }
      )

      if (this.allowanceUnitIsDays) {
        actions.push({
          id: ROUND_TO_ONE_DECIMAL_PLACE,
          name: 'Round to 1 decimal place',
        })
      } else {
        actions.push({
          id: ROUND_TO_THE_NEAREST_HOUR,
          name: 'Round to the nearest hour',
        })
      }

      actions.push(
        {
          id: ROUND_UP_TO_THE_NEAREST_DAY,
          name: 'Round up to the nearest day',
        },
        this.defaultAction
      )

      if (this.allowanceUnitIsDays) {
        actions.push({
          id: ROUND_UP_TO_ONE_DECIMAL_PLACE,
          name: 'Round up to 1 decimal place',
        })
      } else {
        actions.push({
          id: ROUND_UP_TO_THE_NEAREST_HOUR,
          name: 'Round up to the nearest hour',
        })
      }

      actions.push(
        {
          id: ROUND_DOWN_TO_THE_NEAREST_DAY,
          name: 'Round down to the nearest day',
        },
        {
          id: ROUND_DOWN_TO_THE_NEAREST_HALF_DAY,
          name: 'Round down to the nearest half-day',
        }
      )

      if (this.allowanceUnitIsDays) {
        actions.push({
          id: ROUND_DOWN_TO_ONE_DECIMAL_PLACE,
          name: 'Round down to 1 decimal place',
        })
      } else {
        actions.push({
          id: ROUND_DOWN_TO_THE_NEAREST_HOUR,
          name: 'Round down to the nearest hour',
        })
      }

      return actions
    },

    canUseStartDate() {
      return this.startDateChanged && this.startCalendar
    },

    headerTitleDates() {
      if (this.canUseStartDate && this.endDateChanged) {
        return 'start / end'
      }

      if (this.canUseStartDate) {
        return 'start'
      }

      return 'end'
    },

    calendar() {
      if (this.canUseStartDate) {
        return this.startCalendar
      }

      return this.endCalendar
    },

    allowances() {
      return filter(this.employment.allowances, allowance => {
        return allowance.calendar_id === this.calendar.id
      })
    },

    allowanceUnitIsDays() {
      return first(this.allowances).allowance_unit_is_days
    },

    allowanceTypes() {
      return flatMap(this.allowances, 'allowance_type')
    },
  },

  mounted() {
    this.setAction(this.defaultAction)
  },

  methods: {
    async fetchProrateAllowance() {
      this.isLoading = true

      try {
        this.proratedAllowanceReport = await Allowances.prorate({
          company_id: this.activeCompany.id,
          employment_id: this.employment.id,
          calendar_id: this.calendar.id,
          policy: this.selectedAction.id,
        })
      } catch (e) {
        this.error('Error occurred while calculating prorated allowances')
      }

      this.isLoading = false
    },

    async saveBreakdowns() {
      try {
        this.isSaving = true
        await Promise.all(
          this.proratedAllowanceReport.allowances.map(this.submitBreakdown)
        )

        this.$emit('employment-allowance-updated')

        this.success('Proration saved successfully.')
        this.isSaving = false
      } catch (error) {
        this.serverError()

        return
      }

      this.$emit('close-proration-modal')
    },

    async submitBreakdown(breakdown) {
      if (breakdown.isProrated) {
        return this.saveProratedBreakdown(breakdown)
      }

      return this.removeProration(breakdown)
    },

    async saveProratedBreakdown(breakdown) {
      return Allowances.updateEmploymentAllowanceBreakdown(breakdown, {
        company_id: this.activeCompany.id,
        allowance_in_minutes: breakdown.allowanceInMinutes,
        prorate_policy: this.selectedAction.id,
        is_prorated: true,
      })
    },

    async removeProration(breakdown) {
      return Allowances.updateEmploymentAllowanceBreakdown(breakdown, {
        company_id: this.activeCompany.id,
        is_prorated: false,
      })
    },

    setAction(action) {
      this.selectedAction = action

      this.fetchProrateAllowance()
    },
  },
}
</script>
