<template>
  <div />
</template>

<script>
import HandleAuth from '../../mixins/HandleAuth'

export default {
  name: 'Unimpersonate',

  middleware: 'auth',

  layout: 'DefaultLayout',

  mixins: [HandleAuth],

  async created() {
    try {
      await this.unimpersonate()
    } catch (e) {
      await this.$router.replace({ name: 'not-found' })
    }
  },
}
</script>
